import React, { useState, useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { color } from 'styled-system'
import { Row } from 'react-bootstrap'
import { ReactNotifications } from 'react-notifications-component'
import TagManager from 'react-gtm-module'
import {
  CustomPage,
  ContactUsPage,
  CostCalculatorPage,
  CheckoutPage,
  ConfirmationPage,
  DsarPage,
  Error404Page,
  FaqPage,
  HomePage,
  HowItWorksPage,
  LoginPage,
  LogoutPage,
  OurMissionPage,
  ProductPage,
  ProductsPage,
  PrivacyPolicyPage,
  RecoveryPage,
  TermsAndConditionsPage,
  ResetPasswordPage,
  ShoppingCartPage,
  SignUpPage,
  UserPage,
} from 'pages'
import {
  FacebookPixel,
  Footer,
  GoogleAnalytics,
  Header,
  ContentfulContent,
} from 'components'
import * as dotenv from 'dotenv'

import 'app.scss'

dotenv.config()

const tagManagerArgs = {
  dataLayerName: 'PageDataLayer',
  gtmId: 'GTM-56PF5BQ4',
}

TagManager.initialize(tagManagerArgs)

const App = () => {
  const location = useLocation()
  const [bgColor, setBgColor] = useState('#fff')
  const isTestMode = process.env.RAILS_ENV !== 'production'
  const customBgPages = {
    '/consumer-privacy': 'box.primary',
    '/contact-us': 'box.primary',
    '/cost-comparison': 'box.primary',
    '/how-it-works': 'box.primary',
    '/our-mission': 'box.primary',
    '/privacy-policy': 'box.primary',
    '/support-and-faq': 'box.primary',
    '/terms-and-conditions': 'box.primary',
  }

  useEffect(() => {
    setBgColor(customBgPages[location.pathname] || '#fff')
  }, [location.pathname])

  return (
    <React.Fragment>
      <ReactNotifications data-test="notification" />

      <Header />

      <ContentfulContent entryId="5cCtAzs7ZYJrub3ZMbrLzp" id="homepage-popup-sections" isPopUpContent />

      <ContainerWithBg bg={bgColor} className="main-content-with-footer">
        <Row className="app-container">
          <Routes>
            <Route element={<HomePage />} path="/" />
            <Route element={<LoginPage />} path="/login" />
            <Route element={<RecoveryPage />} path="/login/recovery" />
            <Route
              element={<ResetPasswordPage />}
              path="/login/reset-password/:token"
            />
            <Route element={<LogoutPage />} path="/logout" />
            <Route element={<SignUpPage />} path="/signup" />
            <Route element={<ProductsPage />} path="/products" />
            <Route element={<ShoppingCartPage />} path="/cart" />
            <Route element={<CheckoutPage />} path="/checkout" />
            <Route
              element={<ProductPage />}
              path="/product/:slug"
            />

            <Route
              element={<ConfirmationPage />}
              path="/confirmation/:token/:number"
            />

            <Route element={<UserPage />} path="/user" />
            <Route element={<CustomPage id="ccpa" />} path="/consumer-privacy" />

            <Route element={<PrivacyPolicyPage />} path="/privacy-policy" />
            <Route element={<TermsAndConditionsPage />} path="/terms-and-conditions" />
            <Route element={<HowItWorksPage />} path="/how-it-works" />
            <Route element={<OurMissionPage />} path="/our-mission" />
            <Route element={<FaqPage />} path="/support-and-faq" />
            <Route element={<CostCalculatorPage />} path="/cost-comparison" />
            <Route element={<ContactUsPage />} path="/contact-us" />

            <Route element={<UserPage />} path="user/*">
              <Route element={<UserPage />} path=":userMenu" />
            </Route>

            <Route element={<DsarPage />} path="/dsar" />
            <Route element={<Error404Page />} path="*" />
          </Routes>

          <GoogleAnalytics testMode={isTestMode} />
          <FacebookPixel />
          <Footer />
        </Row>
      </ContainerWithBg>
    </React.Fragment>
  )
}

const ContainerWithBg = styled.div`
  ${color}
`

export default App
