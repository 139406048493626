import React from 'react'
import PropTypes from 'prop-types'
import { ContentfulContent } from '../../components'

const Custom = ({ id }) => (
  <ContentfulContent entryName={id} />
)

Custom.propTypes = {
  id: PropTypes.string.isRequired,
}

export default Custom
