import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import App from './App'
import {
  DefaultTitleAndIconLoader,
  LocaleSwitchProvider,
  ResizeProvider,
  SearchIndexingBlocker,
  StoreProvider,
  ThemeProvider,
  TranslatedMetaLoader,
  UserProvider,
} from './modules'
import newImages from '../assets/images/newcb'

const isProd = process.env.RAILS_ENV === 'production'
const oneTrustKey = isProd ? '9141e4d9-59f6-420a-99cb-4691adb24813' : '9141e4d9-59f6-420a-99cb-4691adb24813-test'

const Root = () => {
  useEffect(() => {
    window.rakutenDataLayer = false
    let dln = window.rakutenDataLayer || 'DataLayer'
    if(!window[dln]) {
      window[dln] = {}
    }
    if(!window[dln].events) {
      window[dln].events = {}
    }
    window[dln].events.SPIVersion = window[dln].events.SPIVersion || 'v3.4.1'
  }, [])

  return (
    <BrowserRouter>
      <ThemeProvider>
        <StoreProvider>
          <UserProvider>
            <ResizeProvider>
              <LocaleSwitchProvider>
                <DefaultTitleAndIconLoader />

                <TranslatedMetaLoader
                  data-test="meta-description"
                  metaArray={[
                    { content: 'metaText', name: 'description' },
                    { content: 'metaText', property: 'og:description' },
                  ]}
                />

                <Helmet>
                  <meta
                    content={`${window.origin}${newImages.ogImage}`}
                    key="og:image"
                    property="og:image"
                  />

                  <script
                    src={`https://cdn.cookielaw.org/consent/${oneTrustKey}/OtAutoBlock.js`}
                    type="text/javascript"
                  >
                  </script>

                  <script
                    src={'https://tag.rmp.rakuten.com/126309.ct.js'}
                    type="text/javascript"
                  >
                  </script>

                  <script
                    charset="UTF-8"
                    data-domain-script={oneTrustKey}
                    onload="function OptanonWrapper() {}"
                    src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
                    type="text/javascript"
                  >
                  </script>
                </Helmet>

                <SearchIndexingBlocker />

                <App />
              </LocaleSwitchProvider>
            </ResizeProvider>
          </UserProvider>
        </StoreProvider>
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default Root
