import styled from 'styled-components'
import { border, width } from 'styled-system'

const Separator = styled.hr`
  ${border}
  ${width}

  margin-left: 0;
`

Separator.displayName = 'Separator'

Separator.defaultProps = {
  borderColor: 'primary.main',
}

export default Separator
