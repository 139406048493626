import React from 'react'
import styled from 'styled-components'
import { color, space, layout } from 'styled-system'
import { FormattedMessage } from 'react-intl'
import { Col, Row } from 'react-bootstrap'
import { Heading, Image, Text } from '../../elements'
import images from '../../../assets/images/newcb'

const howItWorksSteps = [
  {
    headerId: 'select' ,
    id: 1,
    img: images.homeSelectIcon,
    text: 'howItWorksSelectDesc',
  },
  {
    headerId: 'enjoy' ,
    id: 2,
    img: images.homeEnjoyIcon,
    text: 'howItWorksEnjoyDesc',
  },
  {
    headerId: 'return' ,
    id: 3,
    img: images.homeReturnIcon,
    text: 'howItWorksReturnDesc',
  },
  {
    headerId: 'repeat' ,
    id: 4,
    img: images.homeRepeatIcon,
    text: 'howItWorksRepeatDesc',
  },
]

const HowItWorks = () => (
  <RelativeRow>
    <ShadowBorderBox bg="white" px={['30px']} py={['40px']}>
      <Heading as="h2" pb={6} textAlign="center">
        <FormattedMessage id="howItWorks" />
      </Heading>

      <Row>
        {howItWorksSteps.map(({ headerId, id, img ,text }) => (
          <CenteredCol key={id}>
            <Col>
              <Image height={110} src={img} width="auto" />
            </Col>

            <UpperCaseHeading as="h6" color="black" m={0} py={5} textAlign="center">
              <FormattedMessage id={headerId} />
            </UpperCaseHeading>

            <Text>
              <FormattedMessage id={text} />
            </Text>
          </CenteredCol>
        ))}
      </Row>
    </ShadowBorderBox>
  </RelativeRow>
)

const RelativeRow = styled(Row)`
  position: relative;

  margin-top: -70px;

  z-index: 2;


  @media (max-width: 400px) {
    margin-top: 10px;
  }
`

const UpperCaseHeading = styled(Heading)`
  font-weight: 600;
  font-family: 'Nunito', sans-serif !important;

  text-transform: uppercase;
`

const ShadowBorderBox = styled(Col)`
  ${color}
  ${space}
  ${layout}

  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.15);
  border-radius: 48px;
`

const CenteredCol = styled(Col)`
  text-align: center;
`

export default HowItWorks
