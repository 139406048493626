import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Box, Currency } from '../../elements'

const OrderElement = ({ isBold, isFree, isHighlighted, translationId, value }) => (
  <Box
    alignItems="center"
    color="dark"
    display="flex"
    flexWrap="wrap"
    my={2}
  >
    <Box width={1/2}>
      {translationId &&
        <FormattedMessage id={translationId} />
      }
    </Box>

    <Box
      color={isHighlighted ? 'primary.dark' : 'dark'}
      display="flex"
      fontSize={isHighlighted ? 3 :1}
      fontWeight={isBold ? 'bold' : 'normal'}
      justifyContent="flex-end"
      width={1/2}
    >
      {isFree
        ? <FormattedMessage id="free" />
        : <Currency value={value} />
      }
    </Box>
  </Box>
)

OrderElement.defaultProps = {
  isBold: false,
  isFree: false,
}

OrderElement.propTypes = {
  isBold: PropTypes.bool,
  isFree: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  translationId: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default OrderElement
