import styled from 'styled-components'
import PropTypes from 'prop-types'
import { color, layout, typography, space } from 'styled-system'
import Box from '../box/Box'
import images from '../../../assets/images/newcb'

const Heading = styled.h1`
  ${color}
  ${layout}
  ${typography}
  ${space}
`

export const HeadingWithBg = styled(Heading)`
  background-image: url(${props => images[props.imageName]});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

  position: relative;
`

Heading.defaultProps = {
  color: 'primary.dark',
}

Heading.displayName = 'Heading'

HeadingWithBg.propTypes = {
  imageName: PropTypes.oneOf([
    'headerBg',
    'headerBgV2',
    'headerBgV3',
    'headerBgV4',
    'headerBgV5',
    'headerBgV6',
    'headerBgV7',
  ]).isRequired,
}

export const HeadingInfoBox = styled(Box)`
  background-image: url(${props => images[props.dark ? 'headerInfoBoxDarkBg' : 'headerInfoBoxBg']});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

  position: relative;

  span, p {
    color: ${props => props.color};
  }
`

HeadingInfoBox.defaultProps = {
  fontWeight: 'bold',
  px: 50,
  py: 40,
}

export default Heading
