import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '../../../elements'
import { staticSectionFieldsPropTypes } from '../../../types'
import RichText from '../rich-text/RichText'

const StaticSection = ({
  custom,
  content: { content },
  boxProps,
}) => (
  content
    ? (
      <Box {...boxProps}>
        <RichText custom={custom} richContent={content.content} />
      </Box>
    )
    : null
)

StaticSection.defaultProps = {
  boxProps: {
    mx: 'auto',
    my: 2,
    px: [3, 0],
    width: 1,
  },
}

StaticSection.propTypes = {
  boxProps: PropTypes.object,
  content: staticSectionFieldsPropTypes,
  custom: PropTypes.object,
}

export default StaticSection
