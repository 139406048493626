import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Container, Row } from 'react-bootstrap'
import { ContentfulContent } from '../../components'
import { Box, Col, HeadingWithBg } from '../../elements'
import { TranslatedMetaLoader, TranslatedTitleLoader } from '../../modules'

const ContactUs = () => (
  <React.Fragment>
    <TranslatedTitleLoader titleId="pageTitleContactUs" />

    <TranslatedMetaLoader
      metaArray={[
        { content: 'metaTextContent', name: 'description' },
        { content: 'metaTextContent', property: 'og:description' },
        { content: 'pageTitleContactUs', property: 'og:title' },
      ]}
    />

    <Container className="mb-5 content-container">
      <HeadingWithBg
        as="h4"
        color="white"
        imageName="headerBgV4"
        my={30}
        p={35}
        position="relative"
        textAlign="center"
      >
        <FormattedMessage id="contactUs" />
      </HeadingWithBg>

      <Row>
        <Col display="flex" md={{ offset: 3, span: 6 }}>
          <ShadowBox bg="white" mt={4} p={3}>
            <ContentfulContent entryId="3RCgA1u5rDsJplAqU2a31U" />
          </ShadowBox>
        </Col>
      </Row>
    </Container>
  </React.Fragment>
)

const ShadowBox = styled(Box)`
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.15);
  border-radius: 20px;

  img {
    display: inline;
    max-width: 20px;
    height: auto;

    margin-right: 8px;
  }
`

export default ContactUs
