import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { border, height, space } from 'styled-system'
import { FormattedMessage } from 'react-intl'
import { Form } from 'react-bootstrap'
import { omit } from '../../lodash'
import { ErrorMessage } from '../../elements'
import './textfield.scss'

const StyledInput = styled(Form.Control)`
  ${border}
  ${height}
  ${space}

  border-radius: 5px;

  &:disabled, &.disabled {
    background-color: ${props => props.theme.colors.gray.light};
    color: ${props => props.theme.colors.gray.dark} !important;
    opacity: 1;
  }
`

StyledInput.defaultProps = {
  height: '52px',
}

const TextField = (props) => {
  const { errorTranslationId } = props
  const cleanProps = omit(props, ['additionalMessage', 'errorTranslationId', 'label'])

  return (
    <Form.Group controlId={props.name}>
      {props.label &&
        <Form.Label>{props.label}</Form.Label>
      }

      <StyledInput
        className={`${errorTranslationId ? 'invalid-form-control' : ''} textfield-form-control`}
        {...cleanProps}
      />

      {props.additionalMessage &&
        <div className="additional-message">
          <FormattedMessage id={props.additionalMessage} />
        </div>
      }

      {errorTranslationId &&
        <ErrorMessage error={errorTranslationId} />
      }
    </Form.Group>
  )
}

TextField.propTypes = {
  additionalMessage: PropTypes.string,
  disabled: PropTypes.bool,
  errorTranslationId: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  name: PropTypes.string,
  type: PropTypes.string,
}

export default TextField
