import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Col, Container, Row } from 'react-bootstrap'
import { ContentfulContent } from '../../components'
import { Box, HeadingWithBg } from '../../elements'
import { TranslatedMetaLoader, TranslatedTitleLoader } from '../../modules'

const TermsAndConditions = () => (
  <React.Fragment>
    <TranslatedTitleLoader titleId="pageTitleTermsAndConditions" />

    <TranslatedMetaLoader
      metaArray={[
        { content: 'metaTextTos', name: 'description' },
        { content: 'metaTextTos', property: 'og:description' },
        { content: 'pageTitleTermsAndConditions', property: 'og:title' },
      ]}
    />

    <Container className="mb-5 content-container">
      <HeadingWithBg
        as="h4"
        color="white"
        imageName="headerBgV4"
        my={30}
        p={70}
        position="relative"
        textAlign="center"
      >
        <FormattedMessage id="termsAndConditions" />
      </HeadingWithBg>

      <Row>
        <Col md={12}>
          <CustomContentStyle className="line2" pl={0}>
            <ContentfulContent entryId="1g6akMpfiGJuH3dAr20nMY" id="termsAndConditions-sections" />
          </CustomContentStyle>
        </Col>
      </Row>

    </Container>
  </React.Fragment>
)

const CustomContentStyle = styled(Box)`
  h1, h2, h3, h4, h5, h6 {
    color: #444444;

    margin-left: -15px;
  }

  .box {
    h1, h2, h3, h4, h5, h6 {
      color: #444444;

      margin-left: 0;
    }

    background-color: #fff;
    border-radius 4px;
  }

  @media (max-width: 756px) {
    h1 {
      font-size: 24px;
    }
  
  }
`

export default TermsAndConditions
