import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useLocation } from 'react-router-dom'
import { Store } from 'react-notifications-component'
import { Auth, UserContext } from '../../'
import { addNotification } from '../../../utils'
import client from '../../../api/api'

const needAccesPages = ['/user/schedule-pickup', '/user/order-history', '/user/settings', '/user/manage-deposits', '/user/whats-in-my-loop']

const UserProvider = ({ children }) => {
  let navigate = useNavigate()
  const location = useLocation()

  const [isLoading, setIsLoading] = useState(true)
  const [cart, setCart] = useState(null)
  const [user, setUser] = useState(null)

  useEffect(() => {
    refetchUser()
  }, [])

  useEffect(async () => {
    if (!Auth.isTokenExist()) {
      const bearerToken = await Auth.getAndCheckAccessToken()

      if (!bearerToken && !!~needAccesPages.indexOf(location.pathname)) {
        refetchUser(true)

        return false
      }
    }
  }, [location])

  const refetchUser = async (isForce = false) => {
    setIsLoading(true)

    const bearerToken = await Auth.getAndCheckAccessToken()

    if (bearerToken) {
      client.account.accountInfo({ bearerToken })
        .then(({ isSuccess, success }) => {
          if (isSuccess()) {
            setUser(success().data)
          }

          refetchCart()
        })
        .catch(() => refetchCart())
    } else {
      setUser(null)
      refetchCart()

      if (isForce) {
        redirectToLogin()
      }
    }
  }

  const getTokenForRequest = async () => {
    const bearerToken = await Auth.getAndCheckAccessToken()

    return new Promise((resolve, reject) => {
      if (bearerToken) {
        resolve(bearerToken)
      } else {
        setUser(null)
        redirectToLogin()

        throw new Error('user token expired')
      }
    })
  }

  const redirectToLogin = () => {
    Store.removeAllNotifications()

    addNotification({
      messageId: 'notificationYourTokenExpired',
      titleId: 'notificationPleaseLoginForContinue',
      type: 'warning',
    })

    navigate('/login')

    return false
  }

  const refetchCart = async () => {
    const bearerToken = Auth.getAccessToken()
    const orderToken = Auth.getCartToken()

    if (!bearerToken && !orderToken) {
      setCart(null)
      setIsLoading(false)

      return null
    }

    const responseGetCart = await client.cart.show(
      bearerToken ? { bearerToken } : { orderToken },
      { include: 'shipping_address,billing_address,promotions' },
    )

    if (responseGetCart.isSuccess()) {
      const { data, included } = responseGetCart.success()

      setCart({
        ...data.attributes,
        order_billing_address: included[1] ? { ...included[1], type: 'orderAddress' } : [],
        order_shipping_address: included[0] ? { ...included[0], type: 'orderAddress' } : [],
        promotions: included[2] ? { ...included[2], type: 'promotion' } : null,
      })
      setIsLoading(false)
    } else {
      Auth.removeCartToken()

      setCart(null)
      setIsLoading(false)
    }
  }

  const associateGuestCart = async () => {
    const bearerToken = Auth.getAccessToken()
    const orderToken = Auth.getCartToken()

    if (!bearerToken || !orderToken)
      return null

    const responseAssociateGuestCart = await client.cart.associateGuestCart(
      { bearerToken },
      { guest_order_token: orderToken },
    )

    if (responseAssociateGuestCart.isSuccess()) {
      const { data } = responseAssociateGuestCart.success()

      setCart(data.attributes)
      setIsLoading(false)

      Auth.removeCartToken()

      return null
    }
  }

  const getCartToken = () => user ? { bearerToken: Auth.getAccessToken() } : { orderToken: Auth.getCartToken() }

  const value = {
    associateGuestCart: associateGuestCart,
    cart,
    getCartToken: getCartToken,
    getTokenForRequest: getTokenForRequest,
    isLoading,
    me: user || null,
    redirectToLogin: redirectToLogin,
    refetch: refetchUser,
    refetchCart: refetchCart,
  }

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  )
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default UserProvider
