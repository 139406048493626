/**
 * @typedef {Object} CancelablePromise
 * @property {Function} cancelablePromise.cancel - cancel the promise
 * @property {Object}   cancelablePromise.promise - the promise object
 */

/**
 * This method can be used to add cancel to any promise
 * @param {Object}   The Promise object.
 *
 * @return {CancelablePromise}  CancelablePromise
 */
const makeCancelable = (promise) => {
  let hasCanceled = false

  const wrappedPromise = new Promise((resolve, reject) =>
    promise.then(
      value => hasCanceled ? reject({ isCanceled: true }) : resolve(value),
      error => hasCanceled ? reject({ isCanceled: true }) : reject(error),
    ),
  )

  return {
    cancel() {
      hasCanceled = true
    },
    promise: wrappedPromise,
  }
}

export default makeCancelable
