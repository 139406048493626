import PropTypes from 'prop-types'

const sysPropType = PropTypes.shape({
  id: PropTypes.string,
  linkType: PropTypes.string,
  type: PropTypes.string,
})

export const contentfulEntrySysPropTypes = PropTypes.shape({
  contentType: PropTypes.shape({
    sys: sysPropType,
  }),
  createdAt: PropTypes.string,
  environment: PropTypes.shape({
    sys: sysPropType,
  }),
  id: PropTypes.string,
  locale: PropTypes.string,
  revision: PropTypes.number,
  space: PropTypes.shape({
    sys: sysPropType,
  }),
  type: PropTypes.string,
  updatedAt: PropTypes.string,
})

export const contentfulAssetPropType = PropTypes.shape({
  fields: PropTypes.shape({
    file: PropTypes.shape({
      contentType: PropTypes.string,
      details: PropTypes.object,
      fileName: PropTypes.string,
      url: PropTypes.string,
    }),
    title: PropTypes.string,
  }),
  sys: contentfulEntrySysPropTypes,
})

export const contentfulContentPropTypes = PropTypes.shape({
  content: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.array,
      data: PropTypes.object,
      nodeType: PropTypes.string,
    }),
  ),
  data: PropTypes.object,
  nodeType: PropTypes.string,
})

export const highlightedLinkPropTypes = PropTypes.shape({
  target: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
})

export const staticPageFieldsPropTypes = PropTypes.shape({
  coverImage: contentfulAssetPropType,
  pageContent: PropTypes.shape({
    content: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.array,
        data: PropTypes.shape({
          target: PropTypes.shape({
            fields: PropTypes.shape({
              alignment: PropTypes.string,
              highlightedLink: PropTypes.shape({
                target: PropTypes.string,
                title: PropTypes.string,
              }),
              image: contentfulAssetPropType,
              layout: PropTypes.string,
              text: PropTypes.string,
            }),
            sys: contentfulEntrySysPropTypes,
          }),
        }),
        nodeType: PropTypes.string,
      }),
    ),
    data: PropTypes.object,
    nodeType: PropTypes.string,
  }),
  pageTitle: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
})

export const staticSectionFieldsPropTypes = PropTypes.shape({
  content: PropTypes.shape({
    content: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.array,
        data: PropTypes.object,
        nodeType: PropTypes.string,
      }),
    ),
    data: PropTypes.object,
    nodeType: PropTypes.string,
  }),
})

export const staticPageEntryPropTypes = PropTypes.shape({
  fields: staticPageFieldsPropTypes,
  sys: contentfulEntrySysPropTypes,
})

export const contentfulPropTypes = PropTypes.shape({
  client: PropTypes.shape({
    getEntry: PropTypes.func,
  }),
  getEntryId: PropTypes.func,
  locale: PropTypes.string,
  setLocale: PropTypes.func,
})
