import React from 'react'
import { isEmpty, omit } from '../../lodash'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { border, color, layout, typography, space } from 'styled-system'
import { Link as RouterLink } from 'react-router-dom'
import theme from '../../constants/theme'

const defaultStyle = css`
  ${border};
  ${color};
  ${layout};
  ${typography};
  ${space};

  text-decoration: underline;
  font-weight: bold;

  &:hover {
    cursor: pointer;
    ${color};
  }
`

const Link = (props) => {
  const cleanProps = omit(props, ['disabled', 'external', (props.external) ? 'to' : 'href'])
  const disabledButtonProps = omit(cleanProps, ['color', 'onClick'])
  const isExternal = props.external || !isEmpty(props.to.match(/http|mailto|tel/))

  if (props.disabled)
    return <StyledLink color="grey" href="#" style={{ pointerEvents: 'none' }} {...disabledButtonProps} />

  return (
    isExternal
      ? <StyledLink href={props.to} rel="noreferrer" target="_blank" {...cleanProps} />
      : <StyledRouterLink {...cleanProps} />
  )
}

const StyledRouterLink = styled(RouterLink)`
  ${defaultStyle};
`

StyledRouterLink.displayName = 'StyledRouterLink'

const StyledLink = styled.a`
  ${defaultStyle};
`

StyledLink.displayName = 'StyledLink'

Link.defaultProps = {
  color: theme.colors.primary.dark,
  disabled: false,
  external: false,
}

Link.propTypes = {
  disabled: PropTypes.bool,
  external: PropTypes.bool,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
}

export default Link
