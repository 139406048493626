import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'react-bootstrap'
import { Box, Button, Link, MarkdownContent, Text } from '../../../../elements'
import { FAQPanel } from '../../../../components'
import { contentfulEntrySysPropTypes } from '../../../../types'
import ContentBox from '../../content-box/ContentBox'

const ALIGNMENT_AS_JUSTIFY_CONTENT = {
  center: 'center',
  left: 'flex-start',
  right: 'flex-end',
}

const EmbeddedEntryBlock = ({ custom ,data }) => {
  const { fields, sys } = data.target

  switch (sys.contentType.sys.id) {
  case 'contentBox':
    return <ContentBox contentFields={fields} custom={custom} id={sys.id} />

  case 'genericMarkdown':
    return (
      <Text as="div" textAlign={fields.isCenteredAlignment ? 'center' : 'left'}>
        <MarkdownContent text={fields.markdownText} />
      </Text>
    )

  case 'faqItem':
    return <FAQPanel question={fields} />

  case 'alert':
    return (
      <Alert my={2} p={3} variant={fields.type}>
        <MarkdownContent text={fields.message} />
      </Alert>
    )

  case 'button':
    return (
      <Box
        alignItems="center"
        display="flex"
        justifyContent={ALIGNMENT_AS_JUSTIFY_CONTENT[fields.alignment]}
        my={3}
      >
        <Link external={fields.isExternal} to={fields.target}>
          <Button>{fields.label}</Button>
        </Link>
      </Box>
    )

  default:
    return null
  }
}

EmbeddedEntryBlock.propTypes = {
  custom: PropTypes.object,
  data: PropTypes.shape({
    target: PropTypes.shape({
      fields: PropTypes.object,
      sys: contentfulEntrySysPropTypes,
    }),
  }),
}

export default EmbeddedEntryBlock
