import styled from 'styled-components'
import { Button as DefaultButton } from 'react-bootstrap'
import {
  color,
  display,
  layout,
  space,
  typography,
} from 'styled-system'

const Button = styled(DefaultButton)`
  ${color}
  ${display}
  ${layout}
  ${space}
  ${typography}

  &:focus {
    box-shadow: none;
    outline: 0;
  }
`

Button.defaultProps = {
  fontSize: 14,
  fontWeight: 400,
  px: 30,
  py: 10,
  width: ['100%', 'auto'],
}

Button.displayName = 'Button'

export default Button
