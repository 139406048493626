import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { ButtonGroup } from 'react-bootstrap'
import { Box, Button } from '../../elements'
import { Auth, withProfile } from '../../modules'
import { userPropTypes } from '../../types'
import { addNotification, trackEvent } from '../../utils'
import client from '../../api/api'

export const QuantityRowItem = ({ user, lineItemId, quantity }) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleCartChange = async (value) => {
    setIsLoading(true)

    const bearerToken = await Auth.getAndCheckAccessToken()
    const hasUser = !!user.me

    if (hasUser && !bearerToken) {
      user.refetch(true)

      user.redirectToLogin()

      return false
    }

    let cartChangeResponse
    const newQuantityValue = quantity + value

    if (newQuantityValue === 0) {
      cartChangeResponse = await client.cart.removeItem(
        user.getCartToken(),
        lineItemId,
      )
    } else {
      cartChangeResponse = await client.cart.setQuantity(
        user.getCartToken(),
        {
          line_item_id: lineItemId,
          quantity: newQuantityValue,
        },
      )
    }

    if (cartChangeResponse.isSuccess()) {
      user.refetchCart()

      handleSucces(value === 1 ? 'notificationItemAdded' : 'notificationItemRemoved')

      trackEvent('User', 'Changed quantity', 'Checkout Page')
    } else {
      handleError()
    }

    setIsLoading(false)
  }

  const handleSucces = (messageId) => {
    addNotification({
      messageId: messageId,
      titleId: 'notificationSuccessTitle',
      type: 'success',
    })
  }

  const handleError = () => {
    addNotification({
      messageId: 'notificationSomethingWentWrong',
      titleId: 'notificationErrorTitle',
      type: 'danger',
    })
  }

  return (
    <StyledButtonGroup className="mt-4">
      <Button
        className="remove-from-cart"
        disabled={isLoading}
        id="remove-item-button"
        onClick={() => handleCartChange(-1)}
        px={3}
        py={2}
        variant="primary"
      >
        -
      </Button>
      <QuantityItem>
        {quantity}
      </QuantityItem>
      <Button
        className="add-to-cart"
        disabled={isLoading}
        id="add-item-button"
        onClick={() => handleCartChange(1)}
        px={3}
        py={2}
        variant="primary"
      >
        +
      </Button>
    </StyledButtonGroup>
  )
}

const QuantityItem = styled(Box)`
  background-color: ${props => props.theme.colors.primary.dark} !important;
  padding: 10px 20px;

  color: ${props => props.theme.colors.white} !important;
  font-weight: bold;
`

const StyledButtonGroup = styled(ButtonGroup)`
  align-self: end;

  button {
    background-color: ${props => props.theme.colors.primary.dark} !important;
    border: 1px solid ${props => props.theme.colors.primary.dark} !important;

    color: ${props => props.theme.colors.white} !important;
    font-weight: bold;

    &:first-child {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    &:last-child {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
`

QuantityRowItem.propTypes = {
  lineItemId: PropTypes.number,
  quantity: PropTypes.number,
  user: userPropTypes,
}

export default withProfile(QuantityRowItem)
