import React from 'react'
import PropTypes from 'prop-types'
import { contentfulAssetPropType } from '../../../../types'
import { Image } from '../../../../elements'

const EmbeddedAssetBlock = ({ data }) => {
  const { contentType, url } = data.target.fields.file

  switch (contentType) {
  case 'image/jpeg':
  case 'image/svg+xml':
  case 'image/webp':
  case 'image/png':
    return <Image src={`${url}?fm=webp`} />

  default:
    return null
  }
}

EmbeddedAssetBlock.propTypes = {
  data: PropTypes.shape({
    target: contentfulAssetPropType,
  }),
}

export default EmbeddedAssetBlock
