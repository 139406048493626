import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Col, Container, Row } from 'react-bootstrap'
import { ContentfulContent } from '../../components'
import { Box, HeadingWithBg } from '../../elements'
import { TranslatedMetaLoader, TranslatedTitleLoader } from '../../modules'

const Faq = () => (
  <React.Fragment>
    <TranslatedTitleLoader titleId="pageTitleFaq" />

    <TranslatedMetaLoader
      metaArray={[
        { content: 'metaTextFaq', name: 'description' },
        { content: 'metaTextFaq', property: 'og:description' },
        { content: 'pageTitleFaq', property: 'og:title' },
      ]}
    />

    <Container className="mb-5 content-container">
      <HeadingWithBg
        as="h4"
        color="white"
        imageName="headerBgV4"
        my={30}
        p={30}
        position="relative"
        textAlign="center"
      >
        <FormattedMessage id="faq" />
      </HeadingWithBg>

      <Row>
        <Col md={12}>
          <CustomContentStyle className="line2" pl={0}>
            <ContentfulContent entryId="1v1JKbmY0SnylXtqWqQlRl" id="faq-sections" />
          </CustomContentStyle>
        </Col>
      </Row>

    </Container>
  </React.Fragment>
)

const CustomContentStyle = styled(Box)`
  h1, h2, h3, h4, h5, h6 {
    color: #444444;

    margin-left: -15px;
  }
`

export default Faq
