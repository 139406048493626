import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { color } from 'styled-system'
import { FormattedMessage } from 'react-intl'
import { Box, BannerCoverImage, Hide, Link, Text } from '../../../elements'
import { contentfulAssetPropType, contentfulContentPropTypes } from '../../../types'
import { hasProtocol, trackEvent } from '../../../utils'
import RichText from '../rich-text/RichText'
import images from '../../../../assets/images/newcb'

const BannerImage = ({ content: { extraContent, fullWidthImage, imageLink }, isFit, imageHeight }) => {
  const isItExternal = hasProtocol(imageLink)

  return (
    <BannerBox>
      {fullWidthImage
        ? (
          <Hide below={400}>
            <BannerCoverImage
              alt={fullWidthImage.fields.title}
              height={imageHeight}
              image={`${fullWidthImage.fields.file.url}?fm=webp`}
              isFit={isFit}
            />
          </Hide>
        )
        : null
      }
      {extraContent && (
        <ExtraTextBox>
          <Box px={['11%', '14%', '14%']} py={['17%']}>
            <PimpedText
              className="home-banner-text"
              fontSize={[14, 20, 22, 26]}
              fontWeight="bold"
              lineHeight={['26px']}
            >
              <RichText richContent={extraContent.content} />
            </PimpedText>

            <Text m={0} textAlign="center">
              {imageLink &&
                <StyledLink
                  bg="primary.dark"
                  color="white"
                  external={isItExternal}
                  fontSize={[12, 16, 20]}
                  fontWeight="bold"
                  onClick={() => {trackEvent('User', 'Clicked carousel button')}}
                  to={imageLink}
                >
                  <FormattedMessage id="getStarted" />
                </StyledLink>
              }
            </Text>
          </Box>
        </ExtraTextBox>
      )
      }
    </BannerBox>
  )
}

const BannerBox = styled(Box)`
  position: relative;

  background: rgb(118,121,112);
  background: linear-gradient(47deg, rgba(118,121,112,1) 0%, rgba(189,185,181,1) 80%);

  z-index: 2;
`
const StyledLink = styled(Link)`
  font-family: 'Urbanist', sans-serif !important;
  position: relative;
  border-radius: 20px;

  z-index: 8;

  font-weight: 400;

  padding: 6px 20px;

  line-height: 24px;

  text-decoration: none;

  &:hover {
    ${color};
    text-decoration: underline;
  }
`

const PimpedText = styled(Box)`
  font-family: 'SouvenirStd', sans-serif !important;

  background: url(${images.bannerTextPimp});
  background-repeat: no-repeat;
  background-size: 16%;
  background-position: 39px bottom;

  padding-bottom: 20px;
  margin-bottom: 0;

  @media (max-width: 756px) {
    background: none;

    padding-bottom: 0;

    line-height: 14px;
  }

  @media (max-width: 870px) {
    background: none;

    padding-bottom: 0;

    line-height: 20px;
  }


`

const ExtraTextBox = styled(Box)`
  position: absolute;
  top: 20%;
  left: 20%;

  width: 60%;
  min-height: 54%;

  background: url(${images.bannerExtraTextBg});
  background-size: contain;  
  background-repeat: no-repeat;
  background-position: center center;

  @media (max-width: 756px) {
    top: 10%;
    left: 0;
  }

  @media (max-width: 400px) {
    position: static;

    width: 100%;
  }
`

BannerImage.defaultProps = {
  imageHeight: 400,
}

BannerImage.propTypes = {
  content: PropTypes.shape({
    extraContent: contentfulContentPropTypes,
    fullWidthImage: contentfulAssetPropType,
    imageLink: PropTypes.string,
  }),
  imageHeight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  isFit: PropTypes.bool,
}

export default BannerImage
