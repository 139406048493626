import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { find, isEmpty } from '../../lodash'
import { Image } from 'react-bootstrap'
import { Box, Currency, Link, Heading, Text, NoImage } from '../../elements'
import { QuantityRowItem } from '../../components'

const ReviewOrderItem = ({ item, readOnly }) => (
  <Box display="flex" flexWrap={['wrap', 'nowrap']} my={20}>
    <Box alignSelf="center" my={[5, 0]} textAlign="center" width={[1, 156]}>
      <Link to={`/product/${item.slug}`}>
        {isEmpty(item.images.data)
          ? <NoImage />
          : (
            <Image
              fluid
              src={find(item.images.data[0].attributes.styles, { width: '600' }).url}
              style={{ borderRadius: '10px', height: 'auto' }}
            />
          )
        }
      </Link>
    </Box>

    <Box ml={[0, 3]} width={1}>
      <Box display="flex" height="100%" justifyContent="space-between">
        <Box display="flex" flex="1 1 auto" flexDirection="column" justifyContent="space-between">
          <Box>
            <Heading as="h2" fontSize={[4, 3]} fontWeight="bold" my={1}>
              <Link color="dark" to={`/product/${item.slug}`}>
                {item.name}
              </Link>
            </Heading>

            <Text color="gray.main">
              {item.subtitle}
            </Text>
          </Box>

          <Text color="dark" fontSize={[2, 3]} fontWeight="bold" my={1}>
            <Currency value={(item.price - item.deposit_price)} />
            {' + '}
            <Text as="span" fontSize={1} fontWeight="normal">
              <Currency data-test="deposit-price" value={item.deposit_price} /> <FormattedMessage id="deposit" />
            </Text>
          </Text>

          {!readOnly &&
            <Text color="gray.main" my={0}>
              <FormattedMessage
                id="itemInYourCart"
                values={{
                  quantity: item.quantity,
                }}
              />
            </Text>
          }
        </Box>

        {!readOnly &&
          <Box display="flex" flex="1 0 auto" flexDirection="column" justifyContent="space-between">
            <QuantityRowItem lineItemId={item.id} quantity={item.quantity} />
          </Box>
        }
      </Box>
    </Box>
  </Box>
)

ReviewOrderItem.defaultProps = {
  readOnly: false,
}

ReviewOrderItem.propTypes = {
  item: PropTypes.object,
  readOnly: PropTypes.bool,
}

export default ReviewOrderItem
