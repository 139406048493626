import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Col, Container, Row } from 'react-bootstrap'
import { Newsletter } from '../../components'
import { Box, Image, Link, Text } from '../../elements'
import images from '../../../assets/images/newcb'
import { scrollToElement } from '../../utils'
import './footer.scss'

const socialLinks = [
  {
    image: images.icons.instagramIcon,
    link: 'https://www.instagram.com/shoploopstore/',
    name: 'Instagram',
  },
  {
    image: images.icons.facebookIcon,
    link: 'https://www.facebook.com/shoploopstore',
    name: 'Facebook',
  },
]

const externalLinks = [
  {
    image: images.loopSecondary,
    link: 'https://exploreloop.com',
    name: 'exploreloop.com',
  },
  {
    image: images.cbLogo,
    link: 'https://www.charliebanana.com/',
    name: 'charliebanana.com',
  },
]

const footerLinks = [
  {
    textId: 'contactUs',
    to: '/contact-us',
  },
  {
    textId: 'supportAndFaq',
    to: '/support-and-faq',
  },
  {
    textId: 'privacyPolicy',
    to: '/privacy-policy',
  },
  {
    textId: 'termsAndConditions',
    to: '/terms-and-conditions',
  },
]

const Footer = () => {
  const date = new Date()

  return (
    <Container className="footer" fluid>
      <Col lg={{ offset: 1, span:10 }} md={12}>
        <Row className="footer-main normal-text">
          <Col lg={3} md={3}>
            {footerLinks.map(({ textId, to }) => (
              <div key={to}>
                <Link className="footer-link" color="white" onClick={scrollToElement} to={to}>
                  <FormattedMessage id={textId} />
                </Link>
              </div>
            ))}
            <button className="ot-sdk-show-settings" id="ot-sdk-btn">Cookie Settings</button>
          </Col>

          <Col className="visit-our-main-sites footer-column" lg={7} md={7}>
            {externalLinks.map(({ image, name, link }) => (
              <Box
                bg="primary.main"
                borderRadius={18}
                fontSize={16}
                fontWeight={600}
                key={name}
                padding={20}
                textAlign="center"
              >
                <Text color="primary.dark">
                  <FormattedMessage id="learnMoreAbout" />
                </Text>

                <Image
                  alt="Loop"
                  className="footer-logo"
                  mb="1rem"
                  onClick={scrollToElement}
                  src={image}
                />

                <Text mb={0}>
                  <Link
                    border="1px solid"
                    borderColor="primary.dark"
                    borderRadius={30}
                    className="footer-link-box"
                    color="primary.dark"
                    external
                    px={3}
                    py={2}
                    style={{ textDecoration: 'none' }}
                    to={link}
                  >
                    {name}
                  </Link>
                </Text>
              </Box>
            ))}
          </Col>

          <Col className="footer-social footer-column" md={2}>
            <div>
              <FormattedMessage id="follow" />
              <Image alt="Loop" className="footer-logo-loop" onClick={scrollToElement} pl={2} src={images.loopWhite} />
            </div>

            <Row className="social-icons">
              {socialLinks.map((item) => (
                <a className="social-icon-link" href={item.link} key={item.name} rel="noreferrer noopener" target="_blank">
                  <Image alt={item.name} className="social-icon-image" src={item.image} />
                </a>
              ))}
            </Row>
          </Col>
        </Row>

        <Row className="align-items-start justify-content-center">
          <Col md={4}>
            <div>
              <FormattedMessage id="keepInTouch" />
            </div>

            <Newsletter />
          </Col>

          <Col className="footer-social footer-column" md={8}>
            <Image alt="Loop and Charlie Banana logo" className="footer-logo-cb" mb={5} onClick={scrollToElement} src={images.loopByCb} />
          </Col>
        </Row>

        <Row className="justify-content-center small-text">
          <FormattedMessage
            id="allRightsReserved"
            values={{ year: date.getFullYear() }}
          />
        </Row>
      </Col>
    </Container>
  )
}

export default Footer
