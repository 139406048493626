import React from 'react'
import styled from 'styled-components'
import { color, space, typography } from 'styled-system'
import { FormattedMessage } from 'react-intl'
import { Link } from '../../elements'
import { withProfile } from '../../modules'
import { userPropTypes } from '../../types'

const GetStarted = (props) => (
  <StyledLink
    bg="primary.dark"
    color="gray.light"
    mr={[0, 2]}
    to="/products"
    {...props}
  >
    <FormattedMessage id="getStarted" />
  </StyledLink>
)

const StyledLink = styled(Link)`
  font-family: 'Urbanist', sans-serif;

  ${color}
  ${space}
  ${typography}
  
  border-radius: 30px;

  font-weight: 400;

  line-height: 24px;

  cursor: pointer;

  &:hover {
    ${color};
    text-decoration: underline;
  }
`

StyledLink.defaultProps = {
  fontSize: 14,
  px: 60,
  py: 10,
}

GetStarted.propTypes = {
  user: userPropTypes,
}

export default withProfile(GetStarted)
