import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Accordion, Card } from 'react-bootstrap'
import { Box, MarkdownContent, Text } from '../../elements'

const FAQPanel = ({ question }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Accordion activeKey={isOpen}>
      <CustomCard>
        <Card.Header onClick={() => setIsOpen(!isOpen)} style={{ cursor: 'pointer' }}>
          <Box alignItems="center" display="flex" justifyContent="space-between" pl={0}>
            <Text fontSize={2} mb={0}>
              {question.question}
            </Text>

            <Arrow border="2px solid" borderColor="primary.dark" status={isOpen} />
          </Box>
        </Card.Header>
        <Accordion.Collapse eventKey={true}>
          <Card.Body>
            <MarkdownContent text={question.answer} />
          </Card.Body>
        </Accordion.Collapse>
      </CustomCard>
    </Accordion>
  )
}

FAQPanel.propTypes = {
  question: PropTypes.object,
}

const Arrow = styled(Box)`
  height: 7px;
  width: 7px;
  transform: rotate(45deg);
  ${props => props.status
    ? (`
      border-right: 0;
      border-bottom: 0;
    `)
    : (`
      border-top: 0;
      border-left: 0;
    `)
}
`

const CustomCard = styled(Card)`
  margin-bottom: 15px;
`

export default FAQPanel

