import React from 'react'
import PropTypes from 'prop-types'
import MDReactComponent from 'markdown-react-js'
import styled from 'styled-components'
import { color, layout, typography, space } from 'styled-system'
import { Box, Link, Heading, Image } from '../../elements'
import { hasProtocol } from '../../utils'
import { boxWithImageHandler, getKey } from './image-box-utils'

export const handleIterate = (Tag, props, children, level) => {
  const {
    boxContent,
    imageAlignment,
    isBoxingInProgress,
    isBoxingFinished,
  } = boxWithImageHandler({ children, level, props, Tag })

  if (isBoxingInProgress) {
    return (Tag === 'a') ? renderTag(Tag, props, children) : null
  }

  if (isBoxingFinished) {
    const cleanBoxContent = boxContent.filter(content => !(content.Tag === 'a' && content.level > 1))

    return renderBoxWithImage(cleanBoxContent, imageAlignment)
  }

  return renderTag(Tag, props, children)
}

export const TextBlock = ({ textArray }) => (
  <React.Fragment>
    {textArray.map(({ Tag, props, children }) => renderTag(Tag, props, children))}
  </React.Fragment>
)

TextBlock.displayName = 'TextBlock'

TextBlock.propTypes = {
  textArray: PropTypes.array.isRequired,
}

export const renderBoxWithImage = (content, imageAlignment) => {
  const image = content.find(element => !!element.props.src)
  const text = content.filter(element => !element.props.src)
  const isImageLeft = imageAlignment === 'left'
  const key = getKey(content)

  return (
    <Box alignItems="center" display="flex" flexWrap="wrap" justifyContent="center" key={key} my={4}>
      <Box order={[~~!isImageLeft, 0]} px={[0, 2]} width={[1, 1/2]}>
        {isImageLeft
          ? <Image alt={image.props.alt} pr={[0, 4]} src={`${image.props.src}?fm=webp`} width={1} />
          : <TextBlock textArray={text} />
        }
      </Box>
      <Box order={[~~isImageLeft, 0]} px={[0, 2]} width={[1, 1/2]}>
        {isImageLeft
          ? <TextBlock textArray={text} />
          : <Image alt={image.props.alt} pl={[0, 4]} src={`${image.props.src}?fm=webp`} width={1} />
        }
      </Box>
    </Box>
  )
}

export const renderTag = (Tag, props, children) => {
  switch (Tag) {
  case 'a':
    return <Link color="primary.dark" external={hasProtocol(props.href)} key={props.key} to={props.href}>{children}</Link>

  case 'h1':
    return <Heading color="gray.dark" key={props.key} my={4}>{children}</Heading>

  case 'h2':
    return <Heading color="gray.dark" fontSize={4} key={props.key} my={5}>{children}</Heading>

  case 'h3':
    const hasBg = !!~children[0].indexOf('<<') && children.indexOf('>>')
    const text = hasBg ? children[0].split(' ')[1] : children
    const bgText = hasBg ? children[0].split(' ')[0].replace('<<', '').replace('>>', '').split('.') : null

    return (
      <Heading color="gray.dark" fontSize={3} key={props.key} my={5}>
        {bgText && <HeadingNumber deg={bgText[1]}>{bgText[0]}</HeadingNumber>}
        {text}
      </Heading>
    )

  case 'h4':
    return <Heading color="gray.dark" fontSize={2} key={props.key} my={5}>{children}</Heading>

  case 'hr':
    return <hr />

  case 'img':
    return <Image alt={props.alt} key={props.key} mx="auto" my={4} src={`${props.src}?fm=webp`} width={1} />

  case 'p':
    return <StyledText key={props.key} my={4}>{children}</StyledText>

  default:
    return <Tag {...props}>{children}</Tag>
  }
}

renderTag.propTypes = {
  alt: PropTypes.string,
  href: PropTypes.string,
  key: PropTypes.string,
  src: PropTypes.string,
}

const MarkdownContent = ({ text }) => (
  text
    ? <MDReactComponent onIterate={handleIterate} text={text} />
    : null
)

MarkdownContent.propTypes = {
  text: PropTypes.string,
}

const StyledText = styled.p`
  ${color}
  ${layout}
  ${typography}
  ${space}

  white-space: pre-line;
`
const HeadingNumber = styled.span`
  font-family: 'SouvenirStd', sans-serif;
  font-size: 32px;

  position: relative;
  z-index: 3;
  display: inline-block;
  width: 45px;
  font-weight: bold;
  height: 45px;
  background: #192359;
  color: white;
  border-radius: 100%;
  padding-left: 11px;
  line-height: 50px;

  transform: rotate(${props => `${props.deg}deg`});

  margin-right: 10px;
  margin-left: -40px;
`

export default MarkdownContent
