import React from 'react'
import styled, { css } from 'styled-components'
import { height } from 'styled-system'

const BannerCoverImage = styled(
  ({ isFit, isLoaded, staticContext, ...restProps }) => <img alt={restProps.alt || ''} src={restProps.image} {...restProps} />,
)`
  ${height}

  max-height: 90vh;

  position: relative;

  z-index: 5;

  transition: .6s filter ease-in;

  ${props => !props.isLoaded && css`
    filter: blur(20px);
    transform: scale(1.03);
  `}

  ${props => props.isFit && css`
    width: 100%;
    object-fit: cover;
  `}
`

BannerCoverImage.displayName = 'CoverImage'

BannerCoverImage.defaultProps = {
  isFit: false,
  isLoaded: true,
  ratio: 0,
}

export default BannerCoverImage
