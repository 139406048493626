import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Container } from 'react-bootstrap'
import { ContentfulContent, GetStarted, HowItWorks } from '../../components'
import { Col, HeadingWithBg, Text, Row } from '../../elements'
import { TranslatedMetaLoader, TranslatedTitleLoader } from '../../modules'
import { trackEvent } from '../../utils'
import HomeCarousel from './carousel/HomeCarousel'

const HomePage = () => (
  <React.Fragment>
    <TranslatedTitleLoader titleId="pageTitleHome" />

    <TranslatedMetaLoader
      metaArray={[
        { content: 'metaText', name: 'description' },
        { content: 'metaText', property: 'og:description' },
        { content: 'pageTitleHome', property: 'og:title' },
      ]}
    />

    <HomeCarousel />

    <Container className="mb-5 content-container">
      <HowItWorks />

      <HeadingWithBg
        as="h4"
        color="white"
        imageName="headerBg"
        my={[0, 50]}
        p="30px"
        textAlign="center"
      >
        <FormattedMessage id="whyLoopDiaperService" />
      </HeadingWithBg>

      <ContentfulContent entryId="28KkDJLulsV1uF9nPVuTg2" />

      <Row>
        <Col>
          <Text textAlign="center">
            <GetStarted
              fontSize={21}
              onClick={() => {trackEvent('Navigation', 'Clicked home page bottom button', 'Home Page')}}
            />
          </Text>
        </Col>
      </Row>
    </Container>
  </React.Fragment>
)

export default HomePage
