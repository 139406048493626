import PropTypes from 'prop-types'
import React, { Component, PureComponent } from 'react'
import theme from '../../constants/theme'

const defaultAnchor = { x: 0.5, y: 0.5 }
const defaultBorderColor = theme.colors.primary.dark
const defaultBorderStyle = 'dashed'
const defaultBorderWidth = 4

const optionalStyleProps = {
  borderColor: PropTypes.string,
  borderStyle: PropTypes.string,
  borderWidth: PropTypes.number,
  className: PropTypes.string,
  zIndex: PropTypes.number,
}

export default class LineTo extends Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.fromAnchor = this.parseAnchor(this.props.fromAnchor)
    this.toAnchor = this.parseAnchor(this.props.toAnchor)
    this.delay = this.parseDelay(this.props.delay)
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize)

    this.delay = this.parseDelay(this.props.delay)
    if (typeof this.delay !== 'undefined') {
      this.deferUpdate(this.delay)
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.fromAnchor !== this.props.fromAnchor) {
      this.fromAnchor = this.parseAnchor(this.props.fromAnchor)
    }
    if (nextProps.toAnchor !== this.props.toAnchor) {
      this.toAnchor = this.parseAnchor(this.props.toAnchor)
    }
    this.delay = this.parseDelay(nextProps.delay)
    if (typeof this.delay !== 'undefined') {
      this.deferUpdate(this.delay)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize)

    if (this.t) {
      clearTimeout(this.t)
      this.t = null
    }
  }

  shouldComponentUpdate() {
    // Always update component if the parent component has been updated.
    // The reason for this is that we would not only like to update
    // this component when the props have changed, but also when
    // the position of our target elements has changed.
    // We could return true only if the positions of `from` and `to` have
    // changed, but that may be expensive and unnecessary.
    return true
  }

  onResizeTimeout = null

  onResize = () => {
    if (!this.onResizeTimeout) {
      this.onResizeTimeout = setTimeout(() => {
        this.onResizeTimeout = null
        this.fromAnchor = this.parseAnchor(this.props.fromAnchor)
        this.toAnchor = this.parseAnchor(this.props.toAnchor)
        this.delay = this.parseDelay(this.props.delay)

        this.deferUpdate(0)
      }, 66)
    }
  }

  // Forced update after delay (MS)
  deferUpdate(delay) {
    if (this.t) {
      clearTimeout(this.t)
    }
    this.t = setTimeout(() => this.forceUpdate(), delay)
  }

  parseDelay(value) {
    if (typeof value === 'undefined') {
      return value
    } else if (typeof value === 'boolean' && value) {
      return 0
    }
    const delay = parseInt(value, 10)
    if (isNaN(delay) || !isFinite(delay)) {
      throw new Error(`LinkTo could not parse delay attribute "${value}"`)
    }
    return delay
  }

  parseAnchorPercent(value) {
    const percent = parseFloat(value) / 100
    if (isNaN(percent) || !isFinite(percent)) {
      throw new Error(`LinkTo could not parse percent value "${value}"`)
    }
    return percent
  }

  parseAnchorText(value) {
    // Try to infer the relevant axis.
    switch (value) {
    case 'top':
      return { y: 0 }
    case 'left':
      return { x: 0 }
    case 'middle':
      return { y: 0.5 }
    case 'center':
      return { x: 0.5 }
    case 'bottom':
      return { y: 1 }
    case 'right':
      return { x: 1 }
    default:
      return null
    }
  }

  parseAnchor(value) {
    if (!value) {
      return defaultAnchor
    }
    const parts = value.split(' ')
    if (parts.length > 2) {
      throw new Error('LinkTo anchor format is "<x> <y>"')
    }
    const [x, y] = parts
    return Object.assign({}, defaultAnchor,
      x ? this.parseAnchorText(x) || { x: this.parseAnchorPercent(x) } : {},
      y ? this.parseAnchorText(y) || { y: this.parseAnchorPercent(y) } : {}
    )
  }

  findElement(className) {
    return document.getElementsByClassName(className)[0]
  }

  detect() {
    const { from, to, within = '' } = this.props

    const a = this.findElement(from)
    const b = this.findElement(to)

    if (!a || !b) {
      return false
    }

    const anchor0 = this.fromAnchor
    const anchor1 = this.toAnchor

    const box0 = a.getBoundingClientRect()
    const box1 = b.getBoundingClientRect()

    let offsetX = window.pageXOffset
    let offsetY = window.pageYOffset

    if (within) {
      const p = this.findElement(within)
      const boxp = p.getBoundingClientRect()

      offsetX -= boxp.left + (window.pageXOffset || document.documentElement.scrollLeft) - p.scrollLeft
      offsetY -= boxp.top + (window.pageYOffset || document.documentElement.scrollTop) - p.scrollTop
    }

    const x0 = box0.left + box0.width * anchor0.x + offsetX
    const x1 = box1.left + box1.width * anchor1.x + offsetX
    const y0 = box0.top + box0.height * anchor0.y + offsetY
    const y1 = box1.top + box1.height * anchor1.y + offsetY

    return { x0, x1, y0, y1 }
  }

  render() {
    const points = this.detect()
    return points ? (
      <Line {...points} {...this.props} />
    ) : null
  }
}

LineTo.propTypes = {
  delay: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  from: PropTypes.string.isRequired,
  fromAnchor: PropTypes.string,
  to: PropTypes.string.isRequired,
  toAnchor: PropTypes.string,
  within: PropTypes.string,
  ...optionalStyleProps,
}

export class Line extends PureComponent {
  componentDidMount() {

    // Append rendered DOM element to the container the
    // offsets were calculated for
    this.within.appendChild(this.el)
  }

  componentWillUnmount() {
    this.within.removeChild(this.el)
  }

  findElement(className) {
    return document.getElementsByClassName(className)[0]
  }

  render() {
    const { custom, borderRight, x0, y0, x1, y1, within = '' } = this.props

    this.within = within ? this.findElement(within) : document.body

    const dy = y1 - y0
    const dx = x1 - x0

    const angle = Math.atan2(dy, dx) * 180 / Math.PI
    const length = Math.sqrt(dx * dx + dy * dy)
    const connectedCustomBox = this.findElement('text_7M7YgibUWUYE5Kz2i3eTJ7').getBoundingClientRect()

    const customPositionStyle = {
      height: connectedCustomBox.width - 150,
      left: `${x0 + connectedCustomBox.width - 50}px`,
      position: 'absolute',
      top: `${y0 + 50}px`,
      // Rotate around (x0, y0)
      transform: 'rotate(90deg)',
      transformOrigin: '0 0',
      width: `${length}px`,
      zIndex: Number.isFinite(this.props.zIndex)
        ? String(this.props.zIndex)
        : '1',
    }

    const defaultPositionStyle = {
      height: '100px',
      left: borderRight ? `${x0 + 70}px` : `${x0 - 50}px`,
      position: 'absolute',
      top: borderRight ? `${y0 + 30}px` : `${y0 - 30}px`,
      // Rotate around (x0, y0)
      transform: `rotate(${angle}deg)`,
      transformOrigin: '0 0',
      width: borderRight ? `${length + 120}px` : `${length + 120}px`,
      zIndex: Number.isFinite(this.props.zIndex)
        ? String(this.props.zIndex)
        : '1',
    }

    const positionStyle = custom
      ? customPositionStyle
      : defaultPositionStyle

    const defaultStyle = {
      borderLeft: this.props.borderColor || defaultBorderColor,
      borderLeftStyle: this.props.borderLeftStyle || defaultBorderStyle,
      borderLeftWidth: this.props.borderWidth || defaultBorderWidth,
      borderRight: this.props.borderColor || defaultBorderColor,
      borderRightStyle: this.props.borderRightStyle || defaultBorderStyle,
      borderRightWidth: this.props.borderWidth || defaultBorderWidth,
      borderTopColor: this.props.borderColor || defaultBorderColor,
      borderTopLeftRadius: this.props.borderTopLeftRadius || 0,
      borderTopRightRadius: this.props.borderTopRightRadius || 0,
      borderTopStyle: this.props.borderStyle || defaultBorderStyle,
      borderTopWidth: this.props.borderWidth || defaultBorderWidth,
    }

    const props = {
      className: this.props.className,
      style: Object.assign({}, defaultStyle, positionStyle),
    }

    // We need a wrapper element to prevent an exception when then
    // React component is removed. This is because we manually
    // move the rendered DOM element after creation.
    return (
      <div className="react-lineto-placeholder">
        <div
          className="react-lineto-line"
          ref={(el) => { this.el = el }}
          {...props}
        />
      </div>
    )
  }
}

Line.propTypes = {
  x0: PropTypes.number.isRequired,
  x1: PropTypes.number.isRequired,
  y0: PropTypes.number.isRequired,
  y1: PropTypes.number.isRequired,
  ...optionalStyleProps,
}
