export const LEFT_IMAGE_OPENING_TAG = '<left-image>'
export const LEFT_IMAGE_CLOSING_TAG = '</left-image>'
export const RIGHT_IMAGE_OPENING_TAG = '<right-image>'
export const RIGHT_IMAGE_CLOSING_TAG = '</right-image>'

let isBoxingInProgress = false
let imageAlignment
export let boxContent = []

export const isBoxingStart = (children) => children && children.some(item => (
  item === LEFT_IMAGE_OPENING_TAG || item === RIGHT_IMAGE_OPENING_TAG
))

export const isBoxingFinish = (children) => children && children.some(item => (
  item === LEFT_IMAGE_CLOSING_TAG || item === RIGHT_IMAGE_CLOSING_TAG
))

export const boxWithImageHandler = ({ Tag, props, children, level }) => {
  let contentToRender = []

  const isBoxingStarted = isBoxingStart(children)
  const isBoxingFinished = isBoxingFinish(children)

  if (isBoxingStarted) {
    isBoxingInProgress = true
    imageAlignment = children.indexOf(LEFT_IMAGE_OPENING_TAG) > -1 ? 'left' : 'right'
  }

  if (isBoxingFinished) {
    isBoxingInProgress = false
    contentToRender = [...boxContent]
    boxContent.splice(0, boxContent.length)
  }

  if (!isBoxingStarted && isBoxingInProgress) {
    boxContent.push({ children, level, props, Tag })
  }

  return {
    boxContent: contentToRender,
    imageAlignment,
    isBoxingFinished,
    isBoxingInProgress,
  }
}

export const getKey = (content) => content.map(item => item.props.key).join('-')
