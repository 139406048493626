import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

const CustomNotificationTemplate = ({ backendError, messageId, titleId, type }) => {
  return (
    <div className={`notification__item--${type}`}>
      <div className="notification__content">
        <div className="notification__close"></div>
        {titleId &&
          <div className="notification__title">
            <FormattedMessage id={titleId} />
          </div>
        }

        {messageId &&
          <div className="notification__main__message notification__message">
            <FormattedMessage id={messageId} />
          </div>
        }

        {backendError &&
          <div className="backend__error notification__message">
            {backendError}
          </div>
        }
      </div>
    </div>
  )
}

CustomNotificationTemplate.defaultProps = {
  type: 'default',
}

CustomNotificationTemplate.propTypes = {
  backendError: PropTypes.string,
  messageId: PropTypes.string,
  titleId: PropTypes.string,
  type: PropTypes.oneOf(['success', 'danger', 'info', 'default', 'warning']),
}

export default CustomNotificationTemplate
