import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { FormattedMessage, injectIntl } from 'react-intl'
import favIcons from '../../../assets/images/favicon'

import { NOINDEXED_DOMAINS } from '../../constants'

export const TranslatedTitleLoader = ({ titleId, value }) => (
  <FormattedMessage
    id={titleId}
    values={{ value }}
  >
    {translatedMessage => (
      <Helmet title={translatedMessage[0]} />
    )}
  </FormattedMessage>
)

export const DefaultTitleAndIconLoader = () => (
  <FormattedMessage id="pageTitleDefault">
    {translatedMessage => (
      <Helmet
        defaultTitle={translatedMessage[0]}
        link={[
          { href: favIcons.appleTouchIcon, rel: 'apple-touch-icon' },
          { href: favIcons.defaultFavicon, rel: 'icon' },
          { href: favIcons.androidIcon32, rel: 'icon', sizes: '32x32' },
          { href: favIcons.androidIcon16, rel: 'icon', sizes: '16x16' },
          { color: '#2D9CDB', href: favIcons.safariIcon, rel: 'mask-icon' },
        ]}
        meta={[
          { content: translatedMessage[0], name: 'author' },
          { content: 'index, follow', name: '”robots”' },
        ]}
      />
    )}
  </FormattedMessage>
)

TranslatedTitleLoader.propTypes = {
  titleId: PropTypes.string.isRequired,
  value: PropTypes.string,
}

export const MetaLoader = ({ metaArray, intl, isTranslated }) => {
  const metaArrayWithTranslations = metaArray.map((metaItem) => ({
    ...metaItem,
    content: isTranslated ? metaItem.content : intl.formatMessage({ id: metaItem.content }),
  }))

  return <Helmet meta={metaArrayWithTranslations} />
}

export const TranslatedMetaLoader = injectIntl(MetaLoader)

MetaLoader.defaultProps = {
  isTranslated: false,
}

MetaLoader.propTypes = {
  intl: PropTypes.object.isRequired,
  isTranslated: PropTypes.bool,
  metaArray: PropTypes.array.isRequired,
}

export const SearchIndexingBlocker = ({ env, noindexedDomains, hostname }) => (
  env.NODE_ENV === 'development'
  || noindexedDomains.some(domain => domain === hostname)
    ? (
      <Helmet>
        <meta content="noindex, nofollow" name="robots" />
      </Helmet>
    )
    : null
)

SearchIndexingBlocker.defaultProps = {
  env: process.env,
  hostname: window.location.hostname,
  noindexedDomains: NOINDEXED_DOMAINS,
}

SearchIndexingBlocker.propTypes = {
  env: PropTypes.object.isRequired,
  hostname: PropTypes.string.isRequired,
  noindexedDomains: PropTypes.array.isRequired,
}
