import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Nav } from 'react-bootstrap'
import { Hide } from '../../../elements'
import { withProfile } from '../../../modules'
import { userPropTypes } from '../../../types'

const menu = [
  {
    inDesktopMenu: true,
    linkTo: '/products',
    translationId: 'shop',
  },
  {
    inDesktopMenu: true,
    linkTo: '/how-it-works',
    translationId: 'howItWorks',
  },
  {
    inDesktopMenu: true,
    linkTo: '/cost-comparison',
    translationId: 'costComparison',
  },
  {
    inDesktopMenu: true,
    linkTo: '/our-mission',
    translationId: 'ourMission',
  },
  {
    linkTo: '/support-and-faq',
    translationId: 'faq',
  },
  {
    linkTo: '/user/whats-in-my-loop',
    name: 'whats-in-my-loop',
    translationId: 'whatsInMyLoop',
  },
  {
    linkTo: '/user/schedule-pickup',
    name: 'schedule-pickup',
    translationId: 'schedulePickup',
  },
  {
    linkTo: '/user/order-history',
    name: 'order-history',
    translationId: 'orderHistory',
  },
  {
    linkTo: '/user/manage-deposits',
    name: 'manage-deposits',
    translationId: 'manageDeposits',
  },
  {
    linkTo: '/user/settings',
    name: 'settings',
    translationId: 'accountSettings',
  },
]

const NavigationMenu = ({ closeNav, user: { me }}) => (
  <Nav className="mr-auto">
    {menu.map(({ inDesktopMenu, linkTo, translationId }) => {
      if (inDesktopMenu || me) {
        return (
          inDesktopMenu ? (
            <Nav.Item key={linkTo}>
              <Link className="nav-link" onClick={closeNav} to={linkTo}>
                <FormattedMessage id={translationId} />
              </Link>
            </Nav.Item>
          ) : (
            <Hide above={992} key={linkTo}>
              <Nav.Item>
                <Link className="nav-link" onClick={closeNav} to={linkTo}>
                  <FormattedMessage id={translationId} />
                </Link>
              </Nav.Item>
            </Hide>
          )
        )
      } else {
        return null
      }},
    )}
    <Hide above={992}>
      {me
        ?
        <Nav.Item>
          <Link className="nav-link" onClick={closeNav} to="/logout">
            <FormattedMessage id="signOut" />
          </Link>
        </Nav.Item>
        :
        <Nav.Item>
          <StyledLink className="nav-link darkcolor" onClick={closeNav} to="/login">
            <FormattedMessage id="signUpOrLogin" />
          </StyledLink>
        </Nav.Item>
      }
    </Hide>
  </Nav>
)

NavigationMenu.propTypes = {
  closeNav: PropTypes.func,
  user: userPropTypes,
}

const StyledLink = styled(Link)`
  background-color: ${props => props.theme.colors.primary.main};
  border-radius: 5px;

  text-align: center;
  
  color: ${props => props.theme.colors.primary.dark} !important;
  
  font-weight: bold;

  &:hover {
    color: ${props => props.theme.colors.white};
  }
`

NavigationMenu.displayName = 'NavigationMenu'

export default withProfile(NavigationMenu)
