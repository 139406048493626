import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Form, InputGroup } from 'react-bootstrap'
import { Button, Icon } from '../../elements'
import { TextField } from '../../components'
import { passwordValidator } from '../../utils'

const PasswordInput = ({ errorTranslationId, name, onChange, showHint, value }) => {
  const [showPassword, setShowPassword] = useState(false)

  const passwordStatus = () => passwordValidator(value)

  return (
    <div>
      <PasswordGroup>
        <TextField
          className={errorTranslationId ? 'invalid-form-control' : ''}
          maxLength="100"
          name={name}
          onChange={onChange}
          type={showPassword ? 'text' : 'password'}
        />
        <Button
          bg="white"
          className="primary-button"
          onClick={() => setShowPassword(!showPassword)}
          px={2}
          size="sm"
          type="button"
          variant="primary"
        >
          <Icon icon={showPassword ? 'eye-blocked' : 'eye'} size={20} />
        </Button>
      </PasswordGroup>

      {errorTranslationId && (
        <Form.Control.Feedback className="textfield-form-control-feedback" type="invalid">
          <FormattedMessage id={errorTranslationId} />
        </Form.Control.Feedback>
      )}

      {showHint && value?.length > 0 && (
        <HintContainer>
          <HintTextForPassword
            $status={passwordStatus().isLower}
            className="text-muted"
          >
            <FormattedMessage id="atLeastLetter" />
          </HintTextForPassword>
          <HintTextForPassword
            $status={passwordStatus().isUpper}
            className="text-muted"
          >
            <FormattedMessage id="atLeastUpperLetter" />
          </HintTextForPassword>
          <HintTextForPassword
            $status={passwordStatus().isNumber}
            className="text-muted"
          >
            <FormattedMessage id="atLeastNumber" />
          </HintTextForPassword>
          <HintTextForPassword
            $status={passwordStatus().isLength}
            className="text-muted"
          >
            <FormattedMessage id="minPwChar" />
          </HintTextForPassword>
        </HintContainer>
      )}
    </div>
  )
}

const HintContainer = styled.div`
  margin-top: 8px;
`
HintContainer.displayName = 'HintContainer'

const PasswordGroup = styled(InputGroup)`
  flex-direction: row;

  flex-wrap: nowrap;

  & .form-group {
    flex: auto;

    margin-bottom: 0;

    z-index: 2;

    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  & button {
    width: 3rem;

    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;

    z-index: 1

  }
`

const HintTextForPassword = styled(Form.Text)`
  margin: 0;

  font-size: 12px;
  line-height: 1.15;
  color: ${(props) => (props.$status ? 'rgb(100, 203, 71)' : 'rgb(255, 32, 32)')} !important;

  transition: color 1s linear;
`

HintTextForPassword.displayName = 'HintTextForPassword'

PasswordInput.defaultProps = {
  name: 'password',
  showHint: true,
}

PasswordInput.propTypes = {
  errorTranslationId: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  showHint: PropTypes.bool,
  value: PropTypes.string,
}

export default PasswordInput
