import React from 'react'
import PropTypes from 'prop-types'
import { Box, MarkdownContent } from '../../../../elements'
import { contentfulAssetPropType, highlightedLinkPropTypes } from '../../../../types'
import ContentBoxImage from '../content-box-image/ContentBoxImage'
import HighlightedLink from '../highlighted-link/HighlightedLink'

const EqualLayout = ({
  custom,
  highlightedLink,
  id,
  image,
  isImgRight,
  isTextOnly,
  text,
  wrapperFlexProps,
}) => (
  <Box
    alignItems="center"
    className={`box box_${id}`}
    display="flex"
    justifyContent="space-between"
    mb={isTextOnly ? [0, 3] : 10}
    {...wrapperFlexProps}
  >
    <Box
      className={`text text_${id}`}
      pb={[3, 0]}
      pl={isImgRight ? [3, 5] : [3, 5]}
      pr={isImgRight ? [3, 5] : [3, 5]}
      width={isTextOnly ? 1 : [1, custom.textBox]}
    >
      <MarkdownContent text={text} />

      <HighlightedLink highlightedLink={highlightedLink} />
    </Box>

    <ContentBoxImage
      boxProps={{ width: [1, custom.imgBox] }}
      className={`image image_${id}`}
      fadingImageProps={{
        height: ['auto'],
        width: 1,
      }}
      image={image}
      isTextOnly={isTextOnly}
    />
  </Box>
)

EqualLayout.defaultProps = {
  custom: {
    imgBox: 1/2,
    textBox: 1/2,
  },
}

EqualLayout.propTypes = {
  custom: PropTypes.object,
  highlightedLink: highlightedLinkPropTypes,
  id: PropTypes.string,
  image: contentfulAssetPropType,
  isImgRight: PropTypes.bool.isRequired,
  isTextOnly: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  wrapperFlexProps: PropTypes.object,
}

export default EqualLayout
