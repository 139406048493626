import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { debounce } from '../../lodash'
import { Slider as RebassSlider } from '@rebass/forms'
import { Box, Text } from '../../elements'
import styled from 'styled-components'
import { borderRadius } from 'styled-system'
import theme from '../../constants/theme'

class Slider extends PureComponent {
  constructor(props) {
    super(props)

    this.containerRef = React.createRef()

    this.state = {
      isLeftBoundary: false,
      isRightBoundary: false,
      left: 0,
    }
  }

  componentDidMount() {
    const { defaultValue, value } = this.props

    this.setTooltipPosition(defaultValue || value)

    window.addEventListener('resize', this.debouncedSetResize)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.defaultValue !== this.props.defaultValue) {
      this.setTooltipPosition(this.props.defaultValue)
    }

    if (prevProps.value !== this.props.value) {
      this.setTooltipPosition(this.props.value)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedSetResize)
  }

  onResize = () => {
    const { defaultValue, value } = this.props

    this.setTooltipPosition(defaultValue || value)
  }

  debouncedSetResize = debounce(this.onResize, 200)

  setTooltipPosition = (progressValue) => {
    const { max, min } = this.props
    const tooltipWidth = 80
    const tooltipMiddle = 30
    const range = max - min
    const boxWidth = this.containerRef.current.offsetWidth
    const boxWidthWithoutProgressButton = this.containerRef.current.offsetWidth - 22
    const tooltipStep = (boxWidthWithoutProgressButton) / range
    const position = (progressValue * tooltipStep) - tooltipMiddle

    const { isLeftBoundary, isRightBoundary } = getBoundaries({ boxWidth, position, tooltipWidth })

    const normalizedPosition = normalizePosition({
      boxWidth,
      isLeftBoundary,
      isRightBoundary,
      position,
      tooltipWidth,
    })

    this.setState({
      isLeftBoundary,
      isRightBoundary,
      left: normalizedPosition,
    })
  }

  handleOnChange = (event) => {
    this.setTooltipPosition(event.target.value)
    this.props.onChange(event)
  }

  render() {
    const { isLeftBoundary, isRightBoundary, left } = this.state
    const { defaultValue, max, min, step, tooltipContent, tooltipProps, value } = this.props

    return (
      <Box ref={this.containerRef}>
        {tooltipContent &&
          <Box position="relative" py={6}>
            <Box bottom="1em" left={left} position="absolute">
              <SliderTooltip
                borderRadius={4}
                isLeftBoundary={isLeftBoundary}
                isRightBoundary={isRightBoundary}
                width="100px"
                {...tooltipProps}
              >
                <Text
                  color="baseColorLight"
                  fontSize={1}
                  mb={0}
                  px={2}
                  py={3}
                  textAlign="center"
                >
                  {tooltipContent}
                </Text>
              </SliderTooltip>
            </Box>
          </Box>
        }
        <CustomSlider
          defaultValue={defaultValue}
          max={max}
          min={min}
          onChange={this.handleOnChange}
          step={step}
          value={value}
        />
      </Box>
    )
  }
}

Slider.defaultProps = {
  tooltipProps: {},
}

Slider.propTypes = {
  defaultValue: PropTypes.number,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  tooltipContent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  tooltipProps: PropTypes.object,
  value: PropTypes.number,
}

const SliderTooltip = styled(
  ({ ...restProps }) => <Box {...restProps} />,
)`
  ${borderRadius}

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid ${props => props.theme.colors.primary.main};
    position: absolute;
    left: ${props => getTooltipArrowPosition(props.isLeftBoundary, props.isRightBoundary)};
  }
`

SliderTooltip.displayName = 'SliderTooltip'

const getProgressSliderThumbStyles = () => `
  background: radial-gradient(
    circle at center,
    ${theme.colors.primary.main} 2px,
    white 3px
  );
  border: 1px solid ${theme.colors.primary.main};
  box-shadow: 0px 4px 12px rgba(79, 79, 79, 0.14);
  height: 22px;
  width: 26px;
  border-radius: 50%;
  cursor: pointer;
`

const CustomSlider = styled(RebassSlider)`
  border: none !important;
  background: ${props => props.theme.colors.gray.main} !important;

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  ::-webkit-slider-thumb {
    ${(props) => getProgressSliderThumbStyles(props)}
  }

  ::-moz-range-thumb {
    ${(props) => getProgressSliderThumbStyles(props)}
  }

  ::-ms-thumb {
    ${(props) => getProgressSliderThumbStyles(props)}
  }
`

export const getTooltipArrowPosition = (isLeftBoundary, isRightBoundary) => {
  if (isLeftBoundary) return '6px'
  if (isRightBoundary) return '58px'

  return '32px'
}

export const getBoundaries = ({ boxWidth, position, tooltipWidth }) => ({
  isLeftBoundary: position < 0,
  isRightBoundary: (position + tooltipWidth) > boxWidth,
})

export const normalizePosition = ({
  boxWidth,
  isLeftBoundary,
  isRightBoundary,
  position,
  tooltipWidth,
}) => (
  isLeftBoundary
    ? 0
    : isRightBoundary
      ? boxWidth - tooltipWidth
      : position
)

export default Slider
