import React from 'react'
import { Link } from '../../../../elements'
import { highlightedLinkPropTypes } from '../../../../types'

const HighlightedLink = ({ highlightedLink }) => (
  highlightedLink
    ? (
      <Link
        color="primary.dark"
        display="block"
        fontSize={2}
        fontWeight="bold"
        mt={3}
        style={{ textDecoration: 'underline' }}
        to={highlightedLink.target}
      >
        {highlightedLink.title}
      </Link>
    )
    : null
)

HighlightedLink.propTypes = {
  highlightedLink: highlightedLinkPropTypes,
}

export default HighlightedLink
