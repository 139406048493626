import React from 'react'
import PropTypes from 'prop-types'
import { FormattedDate, FormattedMessage, FormattedNumber } from 'react-intl'
import { Table } from 'react-bootstrap'
import { uniqueId } from '../../lodash'
import './custom-table.scss'

const CustomTable = ({ headerCells, tableContent }) => (
  <Table bordered hover>
    <thead>
      <tr>
        {headerCells.map((cell) => (
          <th key={cell}>
            <FormattedMessage id={cell} />
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {tableContent.map((content) => (
        <tr key={uniqueId()}>
          {Object.entries(content).map((item) => (
            <td key={item[1].value}>
              {item[1].type === 'currency' && (
                <FormattedNumber
                  currency="USD"
                  style="currency"
                  value={item[1].value}
                />
              )}
              {item[1].type === 'textId' && (
                <FormattedMessage id={item[1].value} />
              )}
              {item[1].value && item[1].type === 'date' && (
                <FormattedDate
                  day="numeric"
                  month="long"
                  timeZone="EST"
                  value={item[1].value}
                  year="numeric"
                />
              )}
              {item[1].type === 'simple' && item[1].value}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </Table>
)

CustomTable.propTypes = {
  headerCells: PropTypes.array,
  tableContent: PropTypes.array,
}

CustomTable.defaultProps = {
  headerCells: [],
  tableContent: [],
}

export default CustomTable
