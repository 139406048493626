import React from 'react'
import { Box } from '../../../elements'
import { ContentfulContent } from '../../../components'
import './homeCarousel.scss'

const HomeCarousel = () => (
  <Box id="home-carousel" mb={4}>
    <ContentfulContent entryId="3gqdadjPFZ1nMffjUHv6xM" />
  </Box>
)

export default HomeCarousel
