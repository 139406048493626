import 'react-app-polyfill/ie9'
import React from 'react'
import { render } from 'react-dom'
import Root from '../Root'

document.addEventListener('DOMContentLoaded', () => {
  render(
    <Root />,
    document.body.appendChild(document.createElement('div')),
  )
})
