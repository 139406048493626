const contentfulPages = {
  ccpa: '3zXE53fSPZYAPMoFOdecJy',
  contact: '41vSvcpYvpjxwzpeQJBMUl',
  faq: '65QvD6JigqoQe7SQnBDE9O',
  howItWorks: 'sLo1muaad9Q4Gy6It5Wq4',
  om: '13teSGdrXDELmSWhrqMw7k',
  pp: '5eXYnnuXe9pLxa5Y1O1HQT',
  tos: '5TvJ71fBTBSKdaJ0vzvaDi',
}

export default contentfulPages
