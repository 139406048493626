import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Col, Container, Row } from 'react-bootstrap'
import { ContentfulContent, LineTo, GetStarted } from '../../components'
import { Arrow, Box, Heading, HeadingWithBg, Hide, Text } from '../../elements'
import { TranslatedMetaLoader, TranslatedTitleLoader } from '../../modules'

const HowItWorks = () => (
  <React.Fragment>
    <TranslatedTitleLoader titleId="pageTitleHowItWorks" />

    <TranslatedMetaLoader
      metaArray={[
        { content: 'metaTextHowItWorks', name: 'description' },
        { content: 'metaTextHowItWorks', property: 'og:description' },
        { content: 'pageTitleHowItWorks', property: 'og:title' },
      ]}
    />

    <Container className="mb-5 content-container">

      <HeadingWithBg
        as="h4"
        className="line1"
        color="white"
        imageName="headerBgV2"
        mb={[100, 50]}
        ml={[0, 200]}
        mt={[20, 50]}
        p="30px"
        pl="70px"
        textAlign="center"
      >
        <FormattedMessage id="howItWorks" />
      </HeadingWithBg>

      <ModifiedImageBox mt={[0, -40, -40, -40, -90]}>
        <ContentfulContent entryId="6CbVsYkd1RvNx7CHbJcuUY" id="how-it-works-description" />
      </ModifiedImageBox>

      <Row>
        <Col md={12}>
          <Heading as="h4" color="black" textAlign="center">
            <FormattedMessage id="heresHowToStart" />
            <Arrow mt={30} mx="auto" />
          </Heading>
        </Col>

        <Col md={{ offset: 1, span: 10 }}>
          <RoundedImageBox className="line2">
            <ContentfulContent custom={{ imgBox: '30%', textBox: '70%' }} entryId="246RgssnJqDEvedWDhn31A" id="how-it-works-sectioons" />
          </RoundedImageBox>
        </Col>

        <Col>
          <Text mt={4} textAlign="center">
            <GetStarted fontSize={21} />
          </Text>
        </Col>
      </Row>

      <Hide below={770}>
        <LineTo borderTopLeftRadius="300px" borderTopRightRadius="500px" custom delay={1200} from="line1" fromAnchor="top center" to="image_7FKfjZ7FCC5E1nbnyRKBNw" toAnchor="top right" />
        <LineTo borderRight borderRightStyle="0" borderTopLeftRadius="100%" delay={1400} from="image_7FKfjZ7FCC5E1nbnyRKBNw" fromAnchor="bottom left" to="image_7BbOatySb2U69060xtRk4N" toAnchor="top right" />
        <LineTo borderLeftStyle="0" borderTopRightRadius="100%" delay={1600} from="image_7BbOatySb2U69060xtRk4N" fromAnchor="bottom right" to="image_3hQR2KD9wc1W75AYw2wAyE" toAnchor="top left" />
        <LineTo borderRight borderRightStyle="0" borderTopLeftRadius="100%" delay={2000} from="image_3hQR2KD9wc1W75AYw2wAyE" fromAnchor="bottom left" to="image_6qLAdq5tqUr61eFdx3nOES" toAnchor="top right" />
      </Hide>
    </Container>
  </React.Fragment>
)

const ModifiedImageBox = styled(Box)`
  img {
    border-radius: 20px;

    padding: 80px;
    margin-top: -120px;
  }


  @media (max-width: 750px) {
    img {
      padding: 15px;
      margin-top: -90px;
    }
  }
`

const RoundedImageBox = styled(Box)`
  img {
    border-radius: 20px;
  }
`

export default HowItWorks
