import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { space } from 'styled-system'

const ProductGrid = styled.div`
    ${space}

    display: grid;
    opacity: 1;
    transition: opacity 0.3s;
    justify-content: center;
  
    ${props => css`
      grid-template-columns: repeat(auto-fit, minmax(0, ${props.columnMinWidth}));
      grid-column-gap: ${props.columnGap};
      grid-row-gap: ${props.rowGap};
    `}

    @media (max-width: ${props => props.theme.breakpoints[0]}) {
      ${props => props.mobileColumnGap && css`
        grid-column-gap: ${props.mobileColumnGap};
    `}

    ${props => props.mobileColumnWidth && css`
      grid-template-columns: repeat(auto-fill, minmax(${props.mobileColumnWidth}, 1fr));
    `}
  }
`

ProductGrid.propTypes = {
  columnGap: PropTypes.string,
  columnMinWidth: PropTypes.string,
  isLoading: PropTypes.bool,
  mobileColumnGap: PropTypes.string,
  mobileColumnWidth: PropTypes.string,
  rowGap: PropTypes.string,
}

ProductGrid.defaultProps = {
  columnGap: '34px',
  columnMinWidth: '246px',
  isLoading: false,
  mobileColumnGap: '16px',
  mobileColumnWidth: '100%',
  rowGap: '48px',
}

ProductGrid.displayName = 'ProductGrid'

export default ProductGrid
