import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Form, Row } from 'react-bootstrap'
import { isEmpty } from '../../lodash'
import { confirm } from 'react-bootstrap-confirmation'
import { withProfile } from '../../modules'
import { CreditCard, CreditCardForm, InfoBox } from '../../components'
import { Button, Col, Text, Select, Separator } from '../../elements'
import { addNotification, trackEvent } from '../../utils'
import { userPropTypes } from '../../types'
import client from '../../api/api'

const CreditCardPanel = ({ buttonTextId, isDeleteEnabled, isSaveEnabled, isLoading, onChange, user }) => {
  const [formState, setFormState] = useState()
  const [selectedCreditCardId, setSelectedCreditCardId] = useState('new')
  const [creditCardList, setCreditCards] = useState([])

  useEffect(() => {
    getCreditCards()
  }, [])

  useEffect(() => {
    if (formState)
      onChange(formState)
  }, [formState])

  const getCreditCards = async () => {
    const bearerToken = await user.getTokenForRequest()

    const response = await client.account.creditCardsList({ bearerToken })
    if (response.isSuccess()) {
      const { data } = response.success()

      setCreditCards(data)

      if (!isEmpty(data)) {
        handlPaymentChange({
          target: {
            value: 0,
          },
        })
      }
    }
  }

  const addCreditCardInfo = (state) => {
    setFormState(prevState => ({
      ...prevState,
      ...state,
    }))
  }

  const selectCreditCard = () => {
    onChange(creditCardList[selectedCreditCardId])
  }

  const handlPaymentChange = (event) => {
    const { value } = event.target

    setSelectedCreditCardId(value)
  }

  const handleCardRemove = async () => {
    const result = await confirm('Are you sure?')

    if (result && creditCardList[selectedCreditCardId].id) {
      const bearerToken = await user.getTokenForRequest()

      const response = await client.account.removeCreditCard({ bearerToken }, creditCardList[selectedCreditCardId].id)

      if (response.isSuccess()) {
        getCreditCards()
        setSelectedCreditCardId('new')

        addNotification({
          messageId: 'notificationUpdateUserMsg',
          titleId: 'notificationSuccessTitle',
          type: 'success',
        })

        trackEvent('User', 'Removed a saved credit card', 'User Page - Account settings')
      } else {
        addNotification({
          messageId: 'notificationSomethingWentWrong',
          titleId: 'notificationErrorTitle',
          type: 'danger',
        })
      }
    }
  }

  const hasSavedPaymentList = !isEmpty(creditCardList)

  return (
    <Row className="justify-content-md-center">
      {hasSavedPaymentList &&
        <Col md="12">
          <Form.Group controlId="creditCardList">
            <Form.Label md="4">
              <FormattedMessage id="creditCardList" />*
            </Form.Label>

            <Select
              as="select"
              id="creditCardList"
              name="creditCardList"
              onChange={handlPaymentChange}
              value={selectedCreditCardId}
            >
              {creditCardList.map(({ id, attributes: { last_digits, name } }, index) => (
                <option key={id} value={index}>{`${name} - **** **** **** ${last_digits}`}</option>
              ))}

              {isSaveEnabled &&
                <FormattedMessage id="newCreditCard">
                  {translatedMessage => (
                    <option value="new">
                      {translatedMessage}
                    </option>
                  )}
                </FormattedMessage>
              }
            </Select>
          </Form.Group>
        </Col>
      }

      <Col md="12">
        {isSaveEnabled &&
          <Text fontWeight="bold">
            <FormattedMessage id="paymentMethod" />
          </Text>
        }

        <Separator borderColor="gray.light" />
      </Col>

      {selectedCreditCardId === 'new'
        ? (
          <>
            {isSaveEnabled
              ? (
                <CreditCardForm
                  buttonMessageId="reviewOrder"
                  isLoading={isLoading}
                  onCallback={addCreditCardInfo}
                />
              )
              : (
                <InfoBox variant="info">
                  <p>
                    <FormattedMessage id="youDontHaveSavedCreditCard" />
                  </p>
                </InfoBox>
              )
            }
          </>
        )
        : (
          <CreditCard
            cardNumber={creditCardList[selectedCreditCardId].attributes.last_digits}
            cardType={creditCardList[selectedCreditCardId].attributes.cc_type}
            expiration={{
              month: creditCardList[selectedCreditCardId].attributes.month,
              year: creditCardList[selectedCreditCardId].attributes.year,
            }}
            name={creditCardList[selectedCreditCardId].attributes.name}
          />
        )
      }

      {selectedCreditCardId !== 'new' &&
        <>
          {isSaveEnabled &&
            <Col className="mt-3" md="12">
              <Button
                className="primary-button"
                disabled={isLoading}
                id="cardUpdateButton"
                onClick={selectCreditCard}
                type="submit"
                variant="primary"
                width="100%"
              >
                <FormattedMessage id={buttonTextId} />
              </Button>
            </Col>
          }

          {isDeleteEnabled &&
            <Col className="mt-3" md="12">
              <Button
                className="mt-2"
                color="alert"
                onClick={handleCardRemove}
                type="button"
                variant="link"
                width="100%"
              >
                <FormattedMessage id="removeThisCard" />
              </Button>
            </Col>
          }
        </>
      }
    </Row>
  )
}

CreditCardPanel.defaultProps = {
  buttonTextId: 'reviewOrder',
  isDeleteEnabled: false,
  isSaveEnabled: true,
}

CreditCardPanel.propTypes = {
  buttonTextId: PropTypes.string,
  isDeleteEnabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSaveEnabled: PropTypes.bool,
  onChange: PropTypes.func,
  paymentsList: PropTypes.array,
  user: userPropTypes,
}

export default withProfile(CreditCardPanel)
