import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Pagination } from 'react-bootstrap'
import { inRange } from '../../lodash'
import { scrollToElement } from '../../utils'
import './pagination.scss'

const PaginationComponent = ({
  contentId,
  currentPage,
  setCurrentPage,
  totalPageCount,
}) => {
  const [pageInTheMiddle, setPageInTheMiddle] = useState(undefined)
  const setPageNumber = num => {
    setCurrentPage(num)

    if (totalPageCount > 7) {
      if (inRange(num, 1, 4)) {
        setPageInTheMiddle(4)
      } else if (inRange(num, totalPageCount - 2, totalPageCount + 1)) {
        setPageInTheMiddle(totalPageCount - 3)
      } else {
        setPageInTheMiddle(num)
      }
    }
  }

  useEffect(() => {
    if (totalPageCount > 7) {
      setPageInTheMiddle(4)
    }
  }, [totalPageCount])

  useEffect(() => {
    scrollToElement(contentId, 'aboveElement')
  }, [currentPage])

  return (
    <Pagination>
      <Pagination.First
        disabled={currentPage === 1}
        onClick={() => setPageNumber(currentPage - 1)}
      />
      <Pagination.Item
        active={currentPage === 1}
        data-test="numbered-page-item"
        onClick={() => setPageNumber(1)}
      >
        {1}
      </Pagination.Item>
      {totalPageCount > 7 && (
        <>
          {pageInTheMiddle !== 4 ? (
            <Pagination.Ellipsis />
          ) : (
            <Pagination.Item
              active={currentPage === pageInTheMiddle - 2}
              data-test="numbered-page-item"
              onClick={() => setPageNumber(pageInTheMiddle - 2)}
            >
              {pageInTheMiddle - 2}
            </Pagination.Item>
          )}
          <Pagination.Item
            active={currentPage === pageInTheMiddle - 1}
            data-test="numbered-page-item"
            onClick={() => setPageNumber(pageInTheMiddle - 1)}
          >
            {pageInTheMiddle - 1}
          </Pagination.Item>
          <Pagination.Item
            active={currentPage === pageInTheMiddle}
            data-test="numbered-page-item"
            onClick={() => setPageNumber(pageInTheMiddle)}
          >
            {pageInTheMiddle}
          </Pagination.Item>
          <Pagination.Item
            active={currentPage === pageInTheMiddle + 1}
            data-test="numbered-page-item"
            onClick={() => setPageNumber(pageInTheMiddle + 1)}
          >
            {pageInTheMiddle + 1}
          </Pagination.Item>
          {pageInTheMiddle !== totalPageCount - 3 ? (
            <Pagination.Ellipsis />
          ) : (
            <Pagination.Item
              active={currentPage === pageInTheMiddle + 2}
              data-test="numbered-page-item"
              onClick={() => setPageNumber(pageInTheMiddle + 2)}
            >
              {pageInTheMiddle + 2}
            </Pagination.Item>
          )}
        </>
      )}
      {totalPageCount > 2 && totalPageCount < 8 && (
        <>
          {totalPageCount > 2 && (
            <Pagination.Item
              active={currentPage === 2}
              data-test="numbered-page-item"
              onClick={() => setPageNumber(2)}
            >
              {2}
            </Pagination.Item>
          )}
          {totalPageCount > 3 && (
            <Pagination.Item
              active={currentPage === 3}
              data-test="numbered-page-item"
              onClick={() => setPageNumber(3)}
            >
              {3}
            </Pagination.Item>
          )}
          {totalPageCount > 4 && (
            <Pagination.Item
              active={currentPage === 4}
              data-test="numbered-page-item"
              onClick={() => setPageNumber(4)}
            >
              {4}
            </Pagination.Item>
          )}
          {totalPageCount > 5 && (
            <Pagination.Item
              active={currentPage === 5}
              data-test="numbered-page-item"
              onClick={() => setPageNumber(5)}
            >
              {5}
            </Pagination.Item>
          )}
          {totalPageCount > 6 && (
            <Pagination.Item
              active={currentPage === 6}
              data-test="numbered-page-item"
              onClick={() => setPageNumber(6)}
            >
              {6}
            </Pagination.Item>
          )}
        </>
      )}
      {totalPageCount > 1 && (
        <Pagination.Item
          active={currentPage === totalPageCount}
          data-test="numbered-page-item"
          onClick={() => setPageNumber(totalPageCount)}
        >
          {totalPageCount}
        </Pagination.Item>
      )}
      <Pagination.Last
        disabled={currentPage === totalPageCount}
        onClick={() => setPageNumber(currentPage + 1)}
      />
    </Pagination>
  )
}

PaginationComponent.defaultProps = {
  contentId: undefined,
}

PaginationComponent.propTypes = {
  contentId: PropTypes.string,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  totalPageCount: PropTypes.number,
}

export default PaginationComponent
