import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Loading } from '../../components'
import Modal from 'react-bootstrap/Modal'
import { Box } from '../../elements'
import { isEmpty } from '../../lodash'
import { getCookie, setCookie, makeCancelable } from '../../utils'
import StaticPage from './static-page/StaticPage'
import StaticSection from './static-section/StaticSection'
// import HomePageSection from './home-page-section/HomePageSection'
import BannerImage from './banner-image/BannerImage'
// import CTAMedia from './cta-media/CTAMedia'
import client from '../../api/contentful'
import { CONTENTFUL_PAGES } from '../../constants'

export class ContentfulContent extends PureComponent {
  constructor(props) {
    super(props)

    this.cancelableGetEntry = null
    this.state = {
      content: {},
      contentType: null,
      hasErrors: false,
      isLoading: true,
      isModalVisible: false,
    }
  }

  getEntryId = (entryName) => CONTENTFUL_PAGES[entryName]

  getEntryIdToFetch = (props = this.props) => {
    const { entryId, entryName } = props

    return entryId || this.getEntryId(entryName)
  }

  componentDidUpdate(prevProps) {
    const entryIdToFetch = this.getEntryIdToFetch()
    const prevEntryIdToFetch = this.getEntryIdToFetch(prevProps)

    if (entryIdToFetch !== prevEntryIdToFetch) {
      this.getData(entryIdToFetch)
    }
  }

  componentDidMount() {
    const entryIdToFetch = this.getEntryIdToFetch()

    this.getData(entryIdToFetch)
  }

  componentWillUnmount() {
    this.cancelableGetEntry && this.cancelableGetEntry.cancel()
  }

  getData = (entryId) => {
    if (entryId) {
      this.cancelableGetEntry = makeCancelable(client.getEntry(entryId))
      this.cancelableGetEntry
        .promise
        .then(entry => {
          const popupValue = getCookie('popup')
          const alreadySawPopup = !isEmpty(getCookie('popup')) && popupValue === entry.fields.contentfulIdentifier

          if (!alreadySawPopup && this.props.isPopUpContent) {
            setCookie('popup', entry.fields.contentfulIdentifier)

            setTimeout(() => {
              this.setState({
                isModalVisible: true,
              })
            }, 2000)
          }

          this.setState({
            content: entry.fields,
            contentType: entry.sys.contentType.sys.id,
            identifier: entry.fields.contentfulIdentifier,
            isLoading: false,
          })
        })
        .catch(error => {
          console.log(error)
          if (!error.isCanceled)
            this.setState({
              hasErrors: true,
              isLoading: false,
            })
        })
    } else {
      this.setState({
        isLoading: false,
      })
    }
  }

  handleModalClose = () => {
    this.setState({
      isModalVisible: false,
    })
  }

  render() {
    const { content, contentType, hasErrors, isLoading, isModalVisible } = this.state
    const { custom, hideLoadingSpinner, imageHeight, isFit, isPopUpContent, showErrorMessage } = this.props

    if (isLoading) {
      return hideLoadingSpinner
        ? null
        : <Loading />
    }

    if (hasErrors && showErrorMessage)
      return (
        <Box mx="auto" my={4} px={[3, 0]} width={[1, 2/3]}>
          <FormattedMessage id="somethingWentWrong" />
        </Box>
      )

    if (isPopUpContent && content.content) {
      return (
        <Modal
          onHide={this.handleModalClose}
          show={isModalVisible}
          size="md"
        >
          <Modal.Body>
            <StaticSection content={content} custom={custom} />
          </Modal.Body>
        </Modal>
      )
    }

    switch (contentType) {
    case 'staticSection':
      return <StaticSection content={content} custom={custom} />

    case 'staticPage':
      return <StaticPage content={content} />

    case 'homePageBanner':
      return <BannerImage content={content} imageHeight="auto" isFit={true} width={1} />

    case 'bannerImage':
      return <BannerImage content={content} imageHeight={imageHeight} isFit={isFit} />

      // case 'ctaCarousel':
      //   return <CTACarousel content={content} />

      // case 'ctaMedia':
      //   return <CTAMedia content={content} />

      // case 'homePageSection':
      //   return <HomePageSection content={content} />

      // case 'cardsArea':
      //   return <RichText richContent={content.cardsContent.content} />

      // case 'bannerSection':
      //   return (
      //     content.message
      //       ? <BannerSection content={content} />
      //       : null
      //   )

      // case 'quoteContainer':
      //   return (
      //     content.quoteList
      //       ? <QuoteContainer content={content.quoteList.content} />
      //       : null
      //   )

    default:
      return null
    }
  }
}

ContentfulContent.defaultProps = {
  hideLoadingSpinner: false,
  imageHeight: 400,
  isFit: false,
  isPopUpContent: false,
  showErrorMessage: true,
}

ContentfulContent.propTypes = {
  custom: PropTypes.object,
  entryId: PropTypes.string,
  entryName: PropTypes.string,
  hideLoadingSpinner: PropTypes.bool,
  imageHeight: PropTypes.number,
  isFit: PropTypes.bool,
  isPopUpContent: PropTypes.bool,
  showErrorMessage: PropTypes.bool,
}

export default ContentfulContent
