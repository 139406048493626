import React from 'react'
import PropTypes from 'prop-types'
import { Box, Image } from '../../../../elements'
import { contentfulAssetPropType } from '../../../../types'

const ContentBoxImage = ({ boxProps, className, fadingImageProps, image, isTextOnly }) => {
  const imageUrl = image && image.fields && image.fields.file.url
  const hasImage = imageUrl && !isTextOnly

  if (!hasImage) {
    return null
  }

  return (
    <Box className={className} style={{ position: 'relative', zIndex: 2 }} {...boxProps}>
      <Image
        alt={image.fields.title}
        src={`${imageUrl}?fm=webp`}
        width="100%"
        {...fadingImageProps}
      />
    </Box>
  )
}

ContentBoxImage.defaultProps = {
  isTextOnly: false,
}

ContentBoxImage.propTypes = {
  boxProps: PropTypes.object,
  className: PropTypes.string,
  fadingImageProps: PropTypes.object,
  image: contentfulAssetPropType,
  isTextOnly: PropTypes.bool.isRequired,
}

export default ContentBoxImage
