import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, Heading, Hide, Image, LazyLoad, Text } from '../../../elements'
import { staticPageFieldsPropTypes } from '../../../types'
import RichText from '../rich-text/RichText'

const StaticPage = ({
  content: { coverImage, metaDescription, pageContent, pageTitle, subtitle, title },
  pageContentBoxProps,
}) => (
  <Container className="mt-3 mb-5">
    <Row>
      <Col md="12">
        <Box>
          <Helmet title={pageTitle || title} />
          {metaDescription && (
            <Helmet
              data-testId="meta-helmet"
              meta={[
                { content: metaDescription, name: 'description' },
                { content: metaDescription, property: 'og:description' },
              ]}
            />
          )}

          {coverImage && (
            <Hide below={0}>
              <LazyLoad imageSrc={`${coverImage.fields.file.url}?fm=webp`}>
                {() => (
                  <Image
                    height="400px"
                    image={`${coverImage.fields.file.url}?fm=webp`}
                  />
                )}
              </LazyLoad>
            </Hide>
          )}

          <Box mb={subtitle ? 5 : 2} mt={5} mx="auto" px={2} width={[1, 3/4]}>
            {title &&
              <Heading textAlign="center">
                {title}
              </Heading>
            }

            {subtitle && <Text data-test="subtitle" textAlign="center">{subtitle}</Text>}
          </Box>

          <Box {...pageContentBoxProps}>
            <RichText richContent={pageContent.content} />
          </Box>
        </Box>
      </Col>
    </Row>
  </Container>
)

StaticPage.defaultProps = {
  pageContentBoxProps: {
    mx: 'auto',
    my: 4,
    px: [3, 0],
    width: [1, 3/4],
  },
}

StaticPage.propTypes = {
  content: staticPageFieldsPropTypes,
  pageContentBoxProps: PropTypes.object,
}

export default StaticPage
