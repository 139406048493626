import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { scrollToElement } from '../../utils'
import { Separator, Text } from '..'
import './subscription-summary-links.scss'

const SubscriptionSummaryLinks = ({ showDiaperLink }) => (
  <>
    <Separator width="87%" />
    <Text color="primaryColorDark">
      <FormattedMessage id="whatToDoNext" />
    </Text>
    <div className="summary-link">
      <Link className="link" onClick={() => scrollToElement()} to="/user/subscription">
        <FormattedMessage id="checkSubscriptionSettings" />
      </Link>
    </div>
    <div className="summary-link">
      <Link className="link" onClick={() => scrollToElement()} to="/how-it-works">
        <FormattedMessage id="howDeliveryWorks" />
      </Link>
    </div>
    {showDiaperLink && (
      <div className="summary-link">
        <Link className="link" onClick={() => scrollToElement()} to="/our-mission">
          <FormattedMessage id="moreAboutDiapers" />
        </Link>
      </div>
    )}
  </>
)

SubscriptionSummaryLinks.defaultProps = {
  showDiaperLink: false,
}

SubscriptionSummaryLinks.propTypes = {
  showDiaperLink: PropTypes.bool,
}

export default SubscriptionSummaryLinks
