import styled from 'styled-components'
import { color, layout, typography, space } from 'styled-system'

const Text = styled.p`
  ${color}
  ${layout}
  ${typography}
  ${space}
`

Text.defaultProps = {
  color: 'black',
}

Text.displayName = 'Text'

export default Text
