import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import axios from 'axios'
import { FormattedMessage } from 'react-intl'
import { stringify } from 'qs'
import { Navigate, useNavigate } from 'react-router-dom'
import { concat, isEmpty, isEqual } from '../../lodash'
import { Accordion, Card, Container, Row } from 'react-bootstrap'
import { Box, Col, Button, Checkbox, Heading, Link, Text } from '../../elements'
import { AccountInformation, CreditCardPanel, Loading, ReviewOrder, ShippingMethods } from '../../components'
import { Auth, TranslatedTitleLoader, withProfile } from '../../modules'
import { addNotification, getBackendErrors, scrollToElement, trackEvent } from '../../utils'
import { userPropTypes } from '../../types'
import client from '../../api/api'

const emptyAddressFields = {
  apartment:'',
  city:'',
  country:'',
  firstName:'',
  lastName:'',
  phoneNumber:'',
  state:'',
  streetAddress:'',
  zipCode:'',
}

const statesForPanel = {
  address: 'accountInformation',
  cart: 'accountInformation',
  confirm: 'reviewAndAuthOrder',
  delivery: 'shippingMethod',
  payment: 'paymentMethod',
}

export const CheckoutPage = ({ user }) => {
  let navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [addressesList, setAddressesList] = useState([])
  const [showErrors, setShowErrors] = useState(false)
  const [billingAddressIsSame, setBillingAddressIsSame] = useState(true)
  const [expandedPanel, setExpandedPanel] = useState(null)
  const [accountErrors, setAccountErrors] = useState({
    bill_address: {},
    ship_address: {},
  })

  const [shippingMethod, setShippingMethod] = useState({
    id: null,
    selected_shipping_rate_id: null,
  })

  const [accountInformation, setAccountInformation] = useState({
    billingAddress: emptyAddressFields,
    shippingAddress: emptyAddressFields,
  })

  useEffect(() => {
    setTimeout(() => scrollToElement(expandedPanel, 'aboveElement'), 400)
  }, [expandedPanel])

  useEffect(async () => {
    if (!user.isLoading && user.me) {
      if (!expandedPanel)
        setExpandedPanel(statesForPanel[user.cart.state])

      const bearerToken = await user.getTokenForRequest()

      const response = await client.account.addressesList({ bearerToken })

      if (response.isSuccess()) {
        const { data } = response.success()

        setAddressesList(data)
      }
    }
  }, [user])

  if (user.isLoading)
    return <Loading />

  if (!user.me)
    return <Navigate to="/login?checkout" />

  if (!user.cart || isEmpty(user.cart.line_items))
    return <Navigate to="/products" />

  const orderToken = user.cart.token

  const handleAccountFormChange = (formData) => {
    setAccountInformation(prevState => ({
      ...prevState,
      ...formData,
    }))

    if (!isEmpty(accountErrors.bill_address || isEmpty(accountErrors.ship_address)))
      setAccountErrors({
        bill_address: {},
        ship_address: {},
      })
  }

  const handlePaymentFormChange = (formData) => {
    handleNextStep(formData)
  }

  const handleSameAddressCheck = () => {
    setBillingAddressIsSame(!billingAddressIsSame)
  }

  const handleShippingMethodSelect = (id, selected_shipping_rate_id) => {
    setShippingMethod({
      id,
      selected_shipping_rate_id,
    })
  }

  const handleNextStep = async (creditCardData) => {
    const { billingAddress, billingAddressFormIsValid, shippingAddress, shippingAddressFormIsValid } = accountInformation
    const bearerToken = await user.getTokenForRequest()

    switch (expandedPanel) {
    case 'accountInformation':
      setShowErrors(false)
      setAccountErrors({
        bill_address: {},
        ship_address: {},
      })

      if (shippingAddressFormIsValid && (billingAddressFormIsValid || billingAddressIsSame)) {
        const address = {
          address1: shippingAddress.streetAddress,
          address2: shippingAddress.apartment,
          city: shippingAddress.city,
          country_iso: shippingAddress.country,
          firstname: shippingAddress.firstName,
          lastname: shippingAddress.lastName,
          phone: shippingAddress.phoneNumber,
          state_name: shippingAddress.state,
          zipcode: shippingAddress.zipCode,
        }

        const bAddress = billingAddressIsSame
          ? address
          : {
            address1: billingAddress.streetAddress,
            address2: billingAddress.apartment,
            city: billingAddress.city,
            country_iso: billingAddress.country,
            firstname: billingAddress.firstName,
            lastname: billingAddress.lastName,
            phone: billingAddress.phoneNumber,
            state_name: billingAddress.state,
            zipcode: billingAddress.zipCode,
          }

        const updateOrderDetails = await client.checkout.orderUpdate(
          { bearerToken },
          {
            order: {
              bill_address_attributes: bAddress,
              email: user.me.attributes.email,
              ship_address_attributes: address,
            },
          })

        if (updateOrderDetails.isSuccess()) {
          if (isEmpty(user.cart.order_shipping_address)) {
            let updateOrCreateUserAddress = (shippingAddress.id)
              ? await client.account.updateAddress(
                { bearerToken },
                shippingAddress.id,
                { address },
              )
              : await client.account.createAddress(
                { bearerToken },
                { address },
              )

            if (updateOrCreateUserAddress.isFail()) {
              addNotification({
                messageId: 'somethingWentWrong',
                titleId: 'notificationErrorTitle',
                type: 'danger',
              })
            }
          }

          await client.checkout.orderNext({ orderToken })

          user.refetchCart()

          setExpandedPanel('shippingMethod')

          addNotification({
            messageId: 'orderUpdated',
            titleId: 'notificationSuccessTitle',
            type: 'success',
          })

          trackEvent('User', 'Filled shipping/billing address', 'Checkout Page - Account Information')
        } else {
          addNotification({
            backendError: updateOrderDetails.fail().serverResponse.data.error,
            titleId: 'notificationErrorTitle',
            type: 'danger',
          })
          const backendErrors = getBackendErrors(updateOrderDetails.fail().serverResponse.data.errors)

          const parsedBackendErrors = {
            bill_address: {},
            ship_address: {},
          }

          Object.keys(backendErrors).forEach(key => {
            const splittedKey = key.split('.')

            parsedBackendErrors[splittedKey[0]][splittedKey[1]] = backendErrors[key]
          })

          setAccountErrors(parsedBackendErrors)
        }
      } else {
        setShowErrors(true)
      }
      break
    case 'shippingMethod':
      if (shippingMethod.id && shippingMethod.selected_shipping_rate_id) {
        const updateOrder = await client.checkout.orderUpdate({ orderToken }, {
          order: {
            shipments_attributes: [{
              id: shippingMethod.id,
              selected_shipping_rate_id: shippingMethod.selected_shipping_rate_id,
            }],
          },
        })

        if (updateOrder.isSuccess()) {
          await client.checkout.orderNext({ orderToken })

          user.refetchCart()

          trackEvent('User', 'Selected shipping method', 'Checkout Page - Shipping method')

          setExpandedPanel('paymentMethod')
        } else {
          addNotification({
            messageId: 'somethingWentWrong',
            titleId: 'notificationErrorTitle',
            type: 'danger',
          })
        }
      }
      break
    case 'paymentMethod':
      if (!isEmpty(creditCardData)) {
        setIsLoading(true)
        const payment = await client.checkout.paymentMethods({ bearerToken })

        if (payment.isSuccess()) {
          const { data } = payment.success()
          let addPaymentMethod

          if (creditCardData.id) {
            addPaymentMethod = await client.checkout.addPayment({ orderToken }, {
              payment_method_id: data[0].id,
              source_id: creditCardData.id,
            })
          } else {
            const instance = axios.create()

            instance.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'
            instance.defaults.headers['Authorization'] = `Bearer ${data[0].attributes.preferences.publishable_key}`

            const additionalInfo = {
              address_city: user.cart.order_billing_address.attributes.city,
              address_country: user.cart.order_billing_address.attributes.country_iso,
              address_line1: user.cart.order_billing_address.attributes.address1,
              address_line2: user.cart.order_billing_address.attributes.address2,
              address_state: user.cart.order_billing_address.attributes.state_code,
              address_zip: user.cart.order_billing_address.attributes.zipcode,
            }

            const stripeTokenResponse = await instance.post('https://api.stripe.com/v1/tokens', stringify({
              card: {
                ...additionalInfo,
                cvc: creditCardData.cvv,
                exp_month: creditCardData.month,
                exp_year: creditCardData.year,
                number: creditCardData.number,
              },
            }))

            if (stripeTokenResponse.status === 200) {
              addPaymentMethod = await client.checkout.addPayment({ orderToken }, {
                payment_method_id: data[0].id,
                source_attributes: {
                  cc_type: creditCardData.card.type,
                  gateway_payment_profile_id: stripeTokenResponse.data.id,
                  last_digits: stripeTokenResponse.data.card.last4,
                  month: creditCardData.month,
                  name: creditCardData.name,
                  year: stripeTokenResponse.data.card.exp_year,
                },
              })

              setIsLoading(false)
            } else {
              setIsLoading(false)

              addNotification({
                messageId: 'somethingWentWrong',
                titleId: 'notificationErrorTitle',
                type: 'danger',
              })
            }
          }

          if(addPaymentMethod.isSuccess()) {
            await client.checkout.orderNext({ orderToken })

            setIsLoading(false)

            trackEvent('User', 'Filled payment method', 'Checkout Page - Payment method')

            setExpandedPanel('reviewAndAuthOrder')
          } else {
            setIsLoading(false)

            addNotification({
              messageId: 'somethingWentWrong',
              titleId: 'notificationErrorTitle',
              type: 'danger',
            })
          }
        } else {
          setIsLoading(false)

          addNotification({
            messageId: 'somethingWentWrong',
            titleId: 'notificationErrorTitle',
            type: 'danger',
          })
        }
      }
      break
    case 'reviewAndAuthOrder': {
      setIsLoading(true)

      const updateOrder = await client.checkout.complete({ orderToken })

      if (updateOrder.isSuccess()) {
        const { data } = updateOrder.success()

        setIsLoading(false)

        Auth.removeCartToken()

        user.refetchCart()

        trackEvent('User', 'Confirmed order', 'Checkout Page - Review and authorize order')

        navigate(`/confirmation/${orderToken}/${data.attributes.number}`)
      } else {
        addNotification({
          messageId: 'somethingWentWrong',
          titleId: 'notificationErrorTitle',
          type: 'danger',
        })

        setIsLoading(false)
      }
    }
      break
    default:
      console.log('Invalid option value')
    }
  }

  const showAccountInformationEditButton = ['shippingMethod','paymentMethod','reviewAndAuthOrder']
  const showShippingMethodEditButton = ['paymentMethod', 'reviewAndAuthOrder']
  const showPaymentMethodEditButton = ['reviewAndAuthOrder']
  const showReviewAndAuthOrderEditButton = []
  const billingAndShippingAddressIsSame = isEqual(user.cart.order_shipping_address?.attributes, user.cart.order_billing_address?.attributes)

  return (
    <Container className="mt-5 mb-5">

      <TranslatedTitleLoader titleId="pageTitleCheckout" />

      <Row className="checkout-page">
        <Col md="12">
          <Heading as="h2" className="checkout-page-title" my={5} textAlign="left">
            <FormattedMessage id="orderReview" />
          </Heading>
        </Col>
      </Row>

      <Row>
        <Col md="12">
          <Accordion activeKey={expandedPanel}>
            <StyledCard>
              <CardHeader
                isButtonVisible={!!~showAccountInformationEditButton.indexOf(expandedPanel)}
                onClick={(value) => {setBillingAddressIsSame(billingAndShippingAddressIsSame); setExpandedPanel(value)}}
                titleId="accountInformation"
              />
              <Accordion.Collapse eventKey="accountInformation" id="accountInformation">
                <Card.Body>
                  <AccountInformation
                    addressesList={concat(user.cart.order_shipping_address, addressesList)}
                    addressType="shippingAddress"
                    defaultAddress={user.cart.order_shipping_address}
                    disabledFields={['country']}
                    errors={accountErrors.ship_address}
                    onChange={handleAccountFormChange}
                    showErrors={showErrors}
                  />

                  <Row className="mb-3">
                    <Col>
                      <Checkbox
                        checked={billingAddressIsSame}
                        labelTextId="billingAddressSameAsShippingAddress"
                        name="billingAddressIsSame"
                        onChange={handleSameAddressCheck}
                      />
                    </Col>
                  </Row>

                  {!billingAddressIsSame &&
                    <AccountInformation
                      addressesList={concat(user.cart.order_billing_address, addressesList)}
                      addressType="billingAddress"
                      defaultAddress={user.cart.order_billing_address}
                      errors={accountErrors.bill_address}
                      onChange={handleAccountFormChange}
                      showErrors={showErrors}
                    />
                  }

                  <Row>
                    <Col>
                      <Button
                        className="mt-2"
                        onClick={handleNextStep}
                        type="button"
                        width="100%"
                      >
                        <FormattedMessage id="continueToShippingMethod" />
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </StyledCard>
          </Accordion>
          <Accordion activeKey={expandedPanel}>
            <StyledCard>
              <CardHeader
                isButtonVisible={!!~showShippingMethodEditButton.indexOf(expandedPanel)}
                onClick={setExpandedPanel}
                titleId="shippingMethod"
              />
              <Accordion.Collapse eventKey="shippingMethod" id="shippingMethod">
                <Card.Body>
                  {expandedPanel === 'shippingMethod' &&
                    <ShippingMethods
                      defaultSelectedId={user.cart.shipping_method_id}
                      onChange={handleShippingMethodSelect}
                      selectedShippingMethodId={shippingMethod.selected_shipping_rate_id}
                    />
                  }

                  <Box px="15px">
                    <Button
                      className="mt-2"
                      disabled={!shippingMethod.selected_shipping_rate_id}
                      onClick={handleNextStep}
                      type="button"
                      width="100%"
                    >
                      <FormattedMessage id="continueToPayment" />
                    </Button>
                  </Box>
                </Card.Body>
              </Accordion.Collapse>
            </StyledCard>
          </Accordion>

          <Accordion activeKey={expandedPanel}>
            <StyledCard>
              <CardHeader
                isButtonVisible={!!~showPaymentMethodEditButton.indexOf(expandedPanel)}
                onClick={setExpandedPanel}
                titleId="paymentMethod"
              />
              <Accordion.Collapse eventKey="paymentMethod" id="paymentMethod">
                <Card.Body>
                  <CreditCardPanel
                    isLoading={isLoading}
                    onChange={handlePaymentFormChange}
                  />
                </Card.Body>
              </Accordion.Collapse>
            </StyledCard>
          </Accordion>

          <Accordion activeKey={expandedPanel}>
            <StyledCard>
              <CardHeader
                isButtonVisible={!!~showReviewAndAuthOrderEditButton.indexOf(expandedPanel)}
                onClick={setExpandedPanel}
                titleId="reviewAndAuthOrder"
              />
              <Accordion.Collapse eventKey="reviewAndAuthOrder" id="reviewAndAuthOrder">
                <Card.Body>
                  <ReviewOrder />

                  <Box px="15px">
                    <Button
                      className="mt-2 place-order"
                      disabled={isLoading}
                      onClick={handleNextStep}
                      type="button"
                      width="100%"
                    >
                      <FormattedMessage id="placeOrder" />
                    </Button>

                    <Link
                      color="primary.main"
                      data-test="productsLink"
                      fontWeight="bold"
                      hover={{ color: 'secondary.main' }}
                      to="/cart"
                    >
                      <Button
                        className="mt-2 secondary-button"
                        type="button"
                        width="100%"
                      >
                        <FormattedMessage id="modifyOrder" />
                      </Button>
                    </Link>
                  </Box>
                </Card.Body>
              </Accordion.Collapse>
            </StyledCard>
          </Accordion>
        </Col>
      </Row>
    </Container>
  )
}

const CardHeader = ({ isButtonVisible, onClick, titleId }) => (
  <Card.Header>
    <Box alignItems="center" display="flex" justifyContent="space-between">
      <Text fontSize={3} fontWeight="bold" mb={0}>
        <FormattedMessage id={titleId} />
      </Text>

      <Box>
        {isButtonVisible &&
          <Button
            color="primary.dark"
            fontSize={18}
            fontWeight={700}
            onClick={() => onClick(titleId)}
            py={0}
            type="button"
            variant="link"
          >
            <FormattedMessage id="edit" />
          </Button>
        }
      </Box>
    </Box>
  </Card.Header>
)

CardHeader.propTypes = {
  isButtonVisible: PropTypes.bool,
  onClick: PropTypes.func,
  titleId: PropTypes.string,
}

const StyledCard= styled(Card)`
  margin-bottom: 24px;
  border: 1px solid ${props => props.theme.colors.gray.light}!important;
`

CheckoutPage.propTypes = {
  user: userPropTypes,
}

export default withProfile(CheckoutPage)
