import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Text } from '../../elements'

const Banner = () => (
  <FormattedMessage id="homePageBannerText">
    {translatedMessage => (
      translatedMessage[0] === 'hide'
        ? null
        : (
          <Box bg="primary.dark" className="home-banner" px={2} width="100%">
            <Text color="gray.light" fontSize={['12px', '16px']} fontWeight="bold" lineHeight={['28px', '32px']} mb={0} textAlign="center">
              {translatedMessage}
            </Text>
          </Box>
        )
    )}
  </FormattedMessage>
)

export default Banner
