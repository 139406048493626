import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { border, color, layout } from 'styled-system'
import { Col, Row } from 'react-bootstrap'
import { Box, MarkdownContent } from '../../../../elements'
import { contentfulAssetPropType, highlightedLinkPropTypes } from '../../../../types'
import HighlightedLink from '../highlighted-link/HighlightedLink'

const ShadowedCardLayout = ({
  custom,
  highlightedLink,
  image,
  isImgRight,
  isTextOnly,
  text,
  wrapperFlexProps,
}) => (
  <ShadowedBox
    borderRadius={30}
    ml={[0, 0, isImgRight ? 40 : '150px']}
    mr={[0, 0, isImgRight ? '150px' : 40]}
    my={7}
    {...wrapperFlexProps}
  >
    <RowHeight height={['auto']}>
      <ColorBox
        bg={custom?.boxBg || (isImgRight ? 'box.secondary' : 'box.primary')}
        md={{ order: isImgRight ? 1 : 2, span: isTextOnly ? 12 : 7 }}
      >
        <Box>
          <MarkdownContent text={text} />

          <HighlightedLink highlightedLink={highlightedLink} />
        </Box>
      </ColorBox>

      {!isTextOnly &&
        <ColWithBg
          bg={custom?.boxBg || (isImgRight ? 'box.secondary' : 'box.primary')}
          height={[200, 400, 'auto']}
          md={{ order: isImgRight ? 2 : 1, span: 5 }}
          style={{ padding: 0 }}
        >
          <ImageBg
            bgImage={`${image.fields.file.url}?fm=webp`}
          />
        </ColWithBg>
      }
    </RowHeight>
  </ShadowedBox>
)

const RowHeight = styled(Row)`
  ${layout}

  min-height: 350px;
`

const ColorBox = styled(Col)`
  ${border}
  ${color}

  display: flex;
  align-items: center;


  padding: 0 70px;

  @media (max-width: 1200px) {
    padding-top: 10px;
    padding-bottom: 10px;

  }

  @media (max-width: 500px) {
    padding: 0 15px;
  }
`

const ColWithBg = styled(Col)`
  ${layout}
  ${color}
`

const ImageBg = styled(Box)`
  background-image: url(${props => props.bgImage});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;

  width: auto;
  height: auto;

  min-height: 100%;
`

const ShadowedBox = styled(Box)`
  overflow: hidden;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
`

ShadowedCardLayout.propTypes = {
  custom: PropTypes.object,
  highlightedLink: highlightedLinkPropTypes,
  image: contentfulAssetPropType,
  isImgRight: PropTypes.bool.isRequired,
  isTextOnly: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  wrapperFlexProps: PropTypes.object,
}

export default ShadowedCardLayout
