import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { zIndex } from 'styled-system'
import { Box, MarkdownContent } from '../../../../elements'
import { contentfulAssetPropType, highlightedLinkPropTypes } from '../../../../types'
import ContentBoxImage from '../content-box-image/ContentBoxImage'
import HighlightedLink from '../highlighted-link/HighlightedLink'

const TextCardOnTheImage = ({
  highlightedLink,
  image,
  isImgRight,
  isTextOnly,
  text,
  wrapperFlexProps,
}) => {
  const innerMargin = isTextOnly
    ? null
    : {
      mb: isImgRight ? 2 : [2, '-50px'],
      ml: isImgRight ? [2, 0] : [2, '-20%'],
      mr: isImgRight ? [2, '-20%'] : [2, 0],
      mt: isImgRight ? '-50px' : ['-50px', 0],
    }

  return (
    <Box
      alignItems={isImgRight ? 'flex-start' : 'flex-end'}
      display="flex"
      justifyContent="center"
      mx={[0, 2]}
      pb={[2, isImgRight ? 3: 60]}
      pt={[2, isImgRight ? 60 : 3]}
      {...wrapperFlexProps}
    >
      <ShadowedBox
        bg="white"
        p={3}
        width={isTextOnly ? 1 : ['auto', 1/2]}
        zIndex={1}
        {...innerMargin}
      >
        <MarkdownContent text={text} />

        <HighlightedLink highlightedLink={highlightedLink} />
      </ShadowedBox>

      <ContentBoxImage
        boxProps={{
          p: 0,
        }}
        image={image}
        isTextOnly={isTextOnly}
      />
    </Box>
  )
}

const ShadowedBox = styled(Box)`
  ${zIndex}

  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
`

TextCardOnTheImage.propTypes = {
  highlightedLink: highlightedLinkPropTypes,
  image: contentfulAssetPropType,
  isImgRight: PropTypes.bool.isRequired,
  isTextOnly: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  wrapperFlexProps: PropTypes.object,
}

export default TextCardOnTheImage
