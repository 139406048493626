import React from 'react'
import PropTypes from 'prop-types'
import { contentfulAssetPropType, highlightedLinkPropTypes } from '../../../types'
import ShadowedCardLayout from './shadowed-card-layout/ShadowedCardLayout'
import TextCardOnTheImage from './text-card-on-the-image/TextCardOnTheImage'
import EqualLayout from './equal-layout/EqualLayout'

const ContentBox = ({ custom, contentFields, id }) => {
  const { alignment, highlightedLink, image, layout, text } = contentFields
  const isImgRight = alignment === 'image on the right'
  const isTextOnly = alignment === 'text only'
  const flexDirection = isImgRight
    ? ['column-reverse', 'row']
    : ['column-reverse', 'row-reverse']

  switch (layout) {
  case 'large-shadowed-card-small-logo':
    return (
      <ShadowedCardLayout
        custom={custom}
        highlightedLink={highlightedLink}
        image={image}
        isImgRight={isImgRight}
        isTextOnly={isTextOnly}
        text={text}
        wrapperFlexProps={{ flexDirection }}
      />
    )
  case 'text-card-on-the-image':
    return (
      <TextCardOnTheImage
        custom={custom}
        highlightedLink={highlightedLink}
        image={image}
        isImgRight={isImgRight}
        isTextOnly={isTextOnly}
        text={text}
        wrapperFlexProps={{ flexDirection }}
      />
    )
  default:
    return (
      <EqualLayout
        custom={custom}
        highlightedLink={highlightedLink}
        id={id}
        image={image}
        isImgRight={isImgRight}
        isTextOnly={isTextOnly}
        text={text}
        wrapperFlexProps={{ flexDirection }}
      />
    )
  }
}

ContentBox.propTypes = {
  contentFields: PropTypes.shape({
    alignment: PropTypes.string,
    highlightedLink: highlightedLinkPropTypes,
    image: contentfulAssetPropType,
    layout: PropTypes.string,
    text: PropTypes.string,
  }),
  custom: PropTypes.object,
  id: PropTypes.string,
}

export default ContentBox
