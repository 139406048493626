import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { IntlProvider } from 'react-intl'
import client from '../../api/contentful'
import translations from './translations'

class LocaleSwitchProvider extends PureComponent {
  constructor(props) {
    super(props)

    const locale = 'en-US'
    const localizedMessages = translations[locale]

    this.state = {
      locale,
      localizedMessages,
    }
  }

  componentDidMount() {

    this.getContentfulData()
  }

  getContentfulData = () => {
    client.getEntry('75JhtqKSy3j0m1SP4LQ1w')
      .then(entry => {
        this.setState({
          locale: this.state.locale,
          localizedMessages: {
            ...this.state.localizedMessages,
            ...entry.fields.translationList[this.state.locale],
          },
        })
      })
      .catch(error => {
        console.warn(error)
      })
  }

  render() {
    const { children } = this.props
    const { locale, localizedMessages } = this.state

    return (
      <IntlProvider
        key={locale}
        locale={locale}
        messages={localizedMessages}
      >
        {children}
      </IntlProvider>
    )
  }
}

LocaleSwitchProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LocaleSwitchProvider
