import { useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router-dom'
import { withProfile } from '../../modules'
import { initializeGoogleAnalytics } from '../../utils'
import { userPropTypes } from '../../types'

export const GoogleAnalytics = ({ testMode, user }) => {
  const location = useLocation()

  useEffect(() => {
    const hasUser = !!user.me

    if (hasUser) {
      ReactGA.set({ userId: user.me.id })
      ReactGA.event({
        action: 'Authenticated',
        category: 'User',
      })
    }
  },[user.me])

  useEffect(() => {
    sendLocationToGoogleAnalytics(location)
  },[location.pathname])

  initializeGoogleAnalytics({
    anonymizeIp: true,
    testMode,
  })

  return null
}

GoogleAnalytics.propTypes = {
  testMode: PropTypes.bool.isRequired,
  user: userPropTypes,
}

GoogleAnalytics.defaultProps = {
  testMode: false,
}

export const sendLocationToGoogleAnalytics = (location) => {
  ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search })
}

export default withProfile(GoogleAnalytics)
