import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Col, Container, Row } from 'react-bootstrap'
import { ContentfulContent } from '../../components'
import { Arrow, Box, Heading, HeadingInfoBox, HeadingWithBg, Hide } from '../../elements'
import { TranslatedMetaLoader, TranslatedTitleLoader } from '../../modules'
import images from '../../../assets/images/newcb'

const HowItWorks = () => (
  <React.Fragment>
    <TranslatedTitleLoader titleId="pageTitleOurMission" />

    <TranslatedMetaLoader
      metaArray={[
        { content: 'metaTextOurMission', name: 'description' },
        { content: 'metaTextOurMission', property: 'og:description' },
        { content: 'pageTitleOurMission', property: 'og:title' },
      ]}
    />

    <Container className="mb-5 content-container">
      <HeadingWithBg
        as="h4"
        color="white"
        imageName="headerBgV4"
        mb={[70, 50]}
        ml={[0, 200]}
        mt={[20, 50]}
        p="40px"
        position="relative"
        textAlign="center"
      >
        <FormattedMessage id="ourMission" />

        <Hide below={767}>
          <CustomBox color="gray.dark" width={400}>
            <HeadingInfoBox fontSize={1} ml={20} px={70}>
              <FormattedMessage id="ourMissionHeader" />
            </HeadingInfoBox>
          </CustomBox>
        </Hide>
      </HeadingWithBg>

      <Hide above={767}>
        <CustomMobileBox color="gray.dark" mt={-70} mx="auto">
          <HeadingInfoBox fontSize={2} px={80}>
            <FormattedMessage id="ourMissionHeader" />
          </HeadingInfoBox>
        </CustomMobileBox>
      </Hide>

      <ModifiedImageBox mt={[0, -40, -40, -40, -90]}>
        <ContentfulContent entryId="6VkQAg6itdRCGYRXBVRF3I" id="our-mission-description" />
      </ModifiedImageBox>

      <Row>
        <Col md={12}>
          <Heading as="h4" color="black" textAlign="center">
            <FormattedMessage id="heresHowWeMakeItEasy" />
            <Arrow mt={30} mx="auto" />
          </Heading>
        </Col>

        <Col md={12}>
          <Box className="line2">
            <ContentfulContent custom={{ boxBg: 'white' }} entryId="6BFtRjFZ3ERUafxNgbKx87" id="our-mission-sectioons" imageHeight={[200, 400]} />
          </Box>
        </Col>
      </Row>

    </Container>
  </React.Fragment>
)

const CustomMobileBox = styled(Box)`
  width: 400px;

  @media (max-width: 400px) {
    width: 100%;

    background-size: auto;
    background-repeat: round;
    background-position: 0 0;

    div {
      padding: 40px !important;
    }
  }
`

const ModifiedImageBox = styled(Box)`
  img {
    padding: 80px;
    margin-top: -120px;

    border-radius: 130px;
  }

  .text {
    margin-top: 100px;  
  }

  @media (max-width: 1200px) {
    .text {
      margin-top: 70px;  
    }

    img {
      margin-top: -270px;
    }

  @media (max-width: 767px) {
    .text {
      margin-top: 0;  
    }

    img {
      margin-top: 0;
      padding: 10px;
    }
  }
`

const CustomBox = styled(Box)`
  position: absolute;

  right: 80px;
  top: 50px;

  background-image: url(${props => images.curvedArrow});
  background-repeat: no-repeat;
  background-position: 0 90%;
  background-size: 20%;

  @media (max-width: 924px) {
   top: 60px;
  }

  @media (max-width: 1200px) {
    right: 0;
  }

`

export default HowItWorks
