import React, { useState } from 'react'
import PropTypes from 'prop-types'
import InputMask from 'react-input-mask'
import cardValidator from 'card-validator'
import { FormattedMessage } from 'react-intl'
import { Alert, Col, Form, Row } from 'react-bootstrap'
import { isEmpty } from '../../../lodash'
import { PaymentLogoList, TextField } from '../../'
import { Button, Text } from '../../../elements'
import { withStoreConfig } from '../../../modules'
import {
  createOrPushToArray,
  generateMask,
  hasAnyError,
  isExpiredMonth,
  isValidMonth,
  isValidVerificationValue,
  isValidYear,
  scrollToElement,
} from '../../../utils'

export const CreditCardForm = ({ backendErrors, buttonMessageId, isLoading, onCallback, store }) => {
  const initialState = {
    card: null,
    cvv: null,
    isValid: false,
    isValidCardNumber: false,
    month: null,
    name: null,
    number: null,
    year: null,
  }
  const [formErrors, setFormErrors] = useState({})
  const [state, setState] = useState(initialState)

  const gaps = state.card?.gaps ?? null
  const lengths = state.card?.lengths ?? null
  const mask = generateMask(gaps, lengths)

  const getErrorId = (field) => {
    const mergedErrors = Object.assign(
      {},
      formErrors,
      backendErrors,
    )

    if (isEmpty(mergedErrors) || !mergedErrors[field]) return null

    return mergedErrors[field][0]
  }

  cardValidator.creditCardType.default.updateCard(
    cardValidator.creditCardType.types.VISA,
    {
      lengths: [13, 14, 16, 19],
    },
  )

  const validateFields = () => {
    const {
      card,
      isValidCardNumber,
      month,
      name,
      cvv,
      year,
    } = state
    const errors = {}

    Object.keys(state).reduce((prev, curr) => {
      if (state[curr] === null || state[curr] === '')
        prev[curr] = ['blank']

      return prev
    }, errors)

    if (!isValidCardNumber) {
      errors.number = createOrPushToArray(errors.number, 'invalid')
    }

    if (isEmpty(name)) {
      errors.name = createOrPushToArray(errors.name, 'blank')
    }

    if (isEmpty(month)) {
      errors.month = createOrPushToArray(errors.month, 'blank')
    } else if (!isEmpty(month) && !isEmpty(year) && isExpiredMonth(month, year)) {
      errors.month = createOrPushToArray(errors.month, 'expiredDate')
    } else if (!isEmpty(month) && !isValidMonth(month)) {
      errors.month = createOrPushToArray(errors.month, 'invalid')
    }

    if (isEmpty(year)) {
      errors.year = createOrPushToArray(errors.year, 'blank')
    } else if (!isEmpty(year) && !isValidYear(year)) {
      errors.year = createOrPushToArray(errors.year, 'invalid')
    }

    if (isEmpty(cvv)) {
      errors.cvv = createOrPushToArray(errors.cvv, 'blank')
    } else if (
      !isEmpty(cvv) &&
      !isValidVerificationValue(card, cvv)
    ) {
      errors.cvv = createOrPushToArray(
        errors.cvv,
        'invalid',
      )
    }

    return new Promise((resolve) => {
      const isValid = !hasAnyError(errors)

      resolve({ errors, isValid })
    })
  }

  const handleClick = (event) => {
    event.preventDefault()

    setFormErrors({})

    validateFields()
      .then(({ isValid, errors }) => {
        if (isValid) {
          onCallback(state)
        } else {
          handleError(errors)
        }
      })
  }

  const validateCard = (value) => {
    const validatedInput = cardValidator.number(value)

    setState({
      ...state,
      card: validatedInput.card,
      isValidCardNumber: validatedInput.isValid,
      number: value,
    })
  }

  const getErrorMessageId = (errorCode) => {
    switch (errorCode) {
    default:
      return 'invalidCreditCardUpdate'
    }
  }

  const handleInputChange = ({ target: { name, value } }) => {
    if (name === 'number') {
      validateCard(value)
    } else {
      setState({
        ...state,
        [name]: value.trim(),
      })
    }
  }

  const handleError = (errors) => {
    setFormErrors(errors)

    scrollToElement('invalid-form-control', 'aboveElement', true)
  }

  const renderErrorMessage = () => {
    const error = formErrors?.base
    const letterWithNumbersRegexp = new RegExp(/(^[a-zA-Z])([0-9])*$/)
    const isErrorCode = letterWithNumbersRegexp.test(error)

    return (
      <div className="credit-card-form-error">
        {isErrorCode ? (
          <FormattedMessage id={getErrorMessageId(error)} />
        ) : (
          <Col>
            <Text color="alertColor" fontSize="12px">{error}</Text>
          </Col>
        )}
      </div>
    )
  }

  return (
    <>
      <Row className="mt-3">
        {Object.keys(formErrors).length !== 0 && renderErrorMessage()}

        <form data-test="update-payment-form">
          <FormattedMessage id="cardNumber">
            {(translatedMessage) => (
              <Col>
                <Form.Label htmlFor="cardNumber">{translatedMessage}*</Form.Label>

                <InputMask
                  mask={mask}
                  maskChar={null}
                  onChange={handleInputChange}
                >
                  {(inputProps) => (
                    <TextField
                      {...inputProps}
                      errorTranslationId={getErrorId('number')}
                      name="number"
                      required
                      type="text"
                    />
                  )}
                </InputMask>
              </Col>
            )}
          </FormattedMessage>

          <Col className="mt-2 mb-2" xs="12">
            <PaymentLogoList supportedPaymentMethods={store.config?.accepted_credit_cards} />
          </Col>

          <Col>
            <Form.Label htmlFor="nameOnCard"><FormattedMessage id="nameOnCard" />*</Form.Label>

            <TextField
              errorTranslationId={getErrorId('name')}
              maxLength="200"
              name="name"
              onChange={handleInputChange}
              required
              type="text"
            />
          </Col>

          <Row>
            <Col xs={{ offset: 0, span: 8 }}>
              <Form.Label htmlFor="expirationDate"><FormattedMessage id="expiration" />*</Form.Label>

              <div className="d-flex">
                <FormattedMessage id="monthShortcode">
                  {(translatedMessage) => (
                    <TextField
                      errorTranslationId={getErrorId('month')}
                      maxLength="2"
                      name="month"
                      onChange={handleInputChange}
                      placeholder={translatedMessage}
                      required
                      type="text"
                    />
                  )}
                </FormattedMessage>

                <Text className="expiration-date-separator" fontSize={18} height="52px" lineHeight="50px" m={0} px={1}>
                /
                </Text>

                <FormattedMessage id="yearShortcode">
                  {(translatedMessage) => (
                    <TextField
                      errorTranslationId={getErrorId('year')}
                      maxLength="2"
                      name="year"
                      onChange={handleInputChange}
                      placeholder={translatedMessage}
                      required
                      type="text"
                    />
                  )}
                </FormattedMessage>
              </div>
            </Col>

            <FormattedMessage id="cvv">
              {(translatedMessage) => (
                <Col>
                  <Form.Label htmlFor="securityCode">{translatedMessage}*</Form.Label>

                  <TextField
                    errorTranslationId={getErrorId('cvv')}
                    maxLength="10"
                    name="cvv"
                    onChange={handleInputChange}
                    required
                    type="password"
                  />
                </Col>
              )}
            </FormattedMessage>
          </Row>

          <Row className="justify-content-between">
            <Col className="mt-2 mb-2" xs="12">
              <Text fontSize={1}>
                <FormattedMessage id="creditCardInfoText" />
              </Text>
            </Col>
          </Row>

          <Row className="justify-content-between">
            {getErrorId('payment_method_token') &&
              <Col className="align-items-center" xs="12">
                <Alert variant="danger">
                  <FormattedMessage id={getErrorId('payment_method_token')} />
                </Alert>
              </Col>
            }
          </Row>

        </form>
      </Row>

      <Col className="mt-3" md="12">
        <Button
          className="primary-button"
          disabled={isLoading}
          id="cardUpdateButton"
          onClick={handleClick}
          type="submit"
          variant="primary"
          width="100%"
        >
          <FormattedMessage id={buttonMessageId} />
        </Button>
      </Col>
    </>
  )
}

CreditCardForm.defaultProps = {
  buttonMessageId: 'confirmYourSubscription',
  isLoading: false,
}

CreditCardForm.propTypes = {
  backendErrors: PropTypes.object,
  buttonMessageId: PropTypes.string,
  isLoading: PropTypes.bool,
  onCallback: PropTypes.func.isRequired,
  store: PropTypes.object,
}

export default withStoreConfig(CreditCardForm)
