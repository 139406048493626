import React from 'react'
import PropTypes from 'prop-types'
import { Box , Heading, Link, Text } from '../../../elements'
import { isEmpty, uniqueId } from '../../../lodash'
import EmbeddedAssetBlock from './embedded-asset-block/EmbeddedAssetBlock'
import EmbeddedEntryBlock from './embedded-entry-block/EmbeddedEntryBlock'

const RichText = ({ custom, richContent }) => (
  isEmpty(richContent)
    ? null
    : richContent.map(({ content, data, nodeType }) => {
      switch (nodeType) {
      case 'embedded-asset-block':
        return <EmbeddedAssetBlock data={data} key={uniqueId()} />

      case 'embedded-entry-block':
        return <EmbeddedEntryBlock custom={custom} data={data} key={uniqueId()} />

      default:
        return <NonEmbeddedContent content={content} key={uniqueId()} nodeType={nodeType} />
      }
    })
)

RichText.propTypes = {
  custom: PropTypes.object,
  richContent: PropTypes.array,
}

export const NonEmbeddedContent = ({ content, nodeType }) => {
  switch (nodeType) {
  case 'heading-2':
    return (
      <Box bg="separator.light" mt={4} p={3}>
        <Heading fontSize={3}>{content[0].value}</Heading>
      </Box>
    )
  case 'heading-3':
    return (
      <Box bg="separator.light" mt={4} p={3}>
        <Heading fontSize={3}>{content[0].value}</Heading>
      </Box>
    )
  case 'heading-5':
    return (
      <Box bg="separator.light" mt={4} p={3}>
        <Heading fontSize={3}>{content[0].value}</Heading>
      </Box>
    )
  case 'hyperlink':
    return <Link external={true} to={content.data.uri}>{content.content[0].value}</Link>
  case 'paragraph':
    return (
      <Text>
        {content.map(contentPart => <NonEmbeddedContent content={contentPart} key={uniqueId()} nodeType={contentPart.nodeType} />)}
      </Text>
    )
  case 'text':
    return <Text as="span">{content.value}</Text>
  case 'hr':
    return <hr />
  case 'unordered-list':
    return <ul>{content.map(contentPart => <NonEmbeddedContent content={contentPart} key={uniqueId()} nodeType={contentPart.nodeType} />)}</ul>
  case 'list-item':
    return <li>{content.content[0].content[0].value}</li>
  default:
    return null
  }
}

NonEmbeddedContent.displayName = 'NonEmbeddedContent'

NonEmbeddedContent.propTypes = {
  content: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  nodeType: PropTypes.string,
}

export default RichText
