import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { isEmpty } from '../../../lodash'
import { FormattedDate, FormattedMessage } from 'react-intl'
import { Accordion, Card, Container, Modal, Row, Table } from 'react-bootstrap'
import { Box, Col, Currency, Button, Heading, Hide, SummaryElement, Text } from '../../../elements'
import { ContentfulContent, Loading } from '../../../components'
import { TranslatedTitleLoader, withProfile } from '../../../modules'
import { trackEvent } from '../../../utils'
import { userPropTypes } from '../../../types'
import client from '../../../api/api'

export const ManageDeposit = ({ user }) => {
  const [returnHistory, setReturnHistory] = useState({
    deposit_in_use: 0,
    return_history: [],
    unused_deposit: 0,
  })
  const [isLoading, setIsLoading] = useState(true)
  const [activeReturnId, setActiveReturn] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false)

  useEffect(() => {
    getMyLoop()
  }, [])

  if (user.isLoading)
    return <Loading />

  const getMyLoop = async () => {
    const bearerToken = await user.getTokenForRequest()

    const { data } = await client.fetcher.fetch({
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
      method: 'GET',
      url: 'api/v2/storefront/return_history',
    })

    setReturnHistory(data)
    setIsLoading(false)
  }

  const handleSelectedReturn = (id) => {
    setActiveReturn(activeReturnId === id ? null : id)
  }

  const hasData = !isEmpty(returnHistory.return_history)

  return (
    <Container className="mb-5">

      <TranslatedTitleLoader titleId="pageTitleUserManageDeposits" />

      <Row className="manage-deposits-page">
        <Col md="12">
          <Heading as="h2" className="checkout-page-title" my={5} textAlign="left">
            <FormattedMessage id="manageDeposits" />
          </Heading>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md="7">
          <BorderBox>
            <ContentfulContent entryId="6XwHfjAjDj0Kl08ZZM2G9X" />
          </BorderBox>
        </Col>

        <Col md="5" mt={[3, 0]}>
          <BorderBox>
            <SummaryElement contentTranslationId="allocatedDeposits" currencyValue={returnHistory.deposit_in_use} />
            <SummaryElement contentTranslationId="depositAccount" currencyValue={returnHistory.unused_deposit} />

            <Box mt={3} textAlign="center">
              <Button
                onClick={() => {setIsModalVisible(true); trackEvent('User', 'Clicked to the withdraw my deposit button', 'User Page - Manage deposit')}}
                width="100%"
              >
                <FormattedMessage id="withdrawMyDeposits" />
              </Button>
            </Box>
          </BorderBox>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md="12">
          <StyledCard>
            <Card.Header>
              <Box alignItems="center" display="flex" justifyContent="space-between">
                <Text fontSize={3} mb={0} width={['50%', '25%']}>
                  <FormattedMessage id="returnDate" />
                </Text>
                <Hide below={540}>
                  <Text fontSize={3} mb={0} width="25%">
                    <FormattedMessage id="inboundTrackingNumber" />
                  </Text>
                </Hide>
                <Hide below={400}>
                  <Text fontSize={3} mb={0} width="25%">
                    <FormattedMessage id="returnedItemNumber" />
                  </Text>
                </Hide>
                <Text fontSize={3} mb={0} textAlign="right" width={['50%', '25%']}>
                  <FormattedMessage id="totalAmount" />
                </Text>
              </Box>
            </Card.Header>
          </StyledCard>

          {isLoading && <Loading />}

          {!isLoading && !hasData &&
            <FormattedMessage id="thereIsNoDataToShow" />
          }

          {returnHistory.return_history.map(returnHisttory => (
            <Accordion activeKey={activeReturnId} key={returnHisttory.id}>
              <StyledCard>
                <StyledHeader onClick={() => handleSelectedReturn(returnHisttory.id)}>
                  <Box alignItems="center" display="flex" justifyContent="space-between">
                    <Text fontSize={3} mb={0} width={['50%', '25%']}>
                      {returnHisttory.returned_at &&
                        <FormattedDate
                          data-test="returnHisttory-date"
                          day="numeric"
                          month="long"
                          value={returnHisttory.returned_at}
                          year="numeric"
                        />
                      }
                    </Text>
                    <Hide below={540}>
                      <Text fontSize={3} mb={0} width="25%">
                        {returnHisttory.number}
                      </Text>
                    </Hide>
                    <Hide below={400}>
                      <Text fontSize={3} mb={0} width="25%">
                        {returnHisttory.item_quantity}
                      </Text>
                    </Hide>
                    <Text fontSize={3} mb={0} textAlign="right" width={['50%', '25%']}>
                      <Currency value={returnHisttory.total_price} />
                    </Text>
                  </Box>
                </StyledHeader>

                <Accordion.Collapse eventKey={returnHisttory.id}>
                  <Card.Body>
                    <Row>
                      <Col className="mt-3" md={12}>
                        <Table responsive>
                          <thead>
                            <tr>
                              <th>
                                <FormattedMessage id="productName" />
                              </th>
                              <th>
                                <FormattedMessage id="depositPrice" />
                              </th>
                              <th>
                                <FormattedMessage id="quantity" />
                              </th>
                              <th>
                                <FormattedMessage id="subtotal" />
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {returnHisttory.items.map(item => (
                              <tr key={item.id}>
                                <td>
                                  {item.name}
                                </td>
                                <td>
                                  <Currency value={item.price} />
                                </td>
                                <td>
                                  {item.quantity}
                                </td>
                                <td>
                                  <Currency value={(item.price * item.quantity)} />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </StyledCard>
            </Accordion>
          ))}
        </Col>
      </Row>

      <Modal onHide={() => setIsModalVisible(false)} show={isModalVisible}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="howToReachUs" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ContentfulContent entryId="64sBLHySAl8xitKmppImbD" />
        </Modal.Body>
      </Modal>
    </Container>
  )
}

const BorderBox = styled(Box)``

const StyledHeader = styled(Card.Header)`
  cursor: pointer;
`

const StyledCard = styled(Card)`
  margin-bottom: 24px;
  border: 1px solid ${props => props.theme.colors.gray.light} !important;
`

BorderBox.defaultProps = {
  border: '1px solid',
  borderColor: 'gray.main',
  borderRadius: 1,
  p: 3,
}

ManageDeposit.propTypes = {
  user: userPropTypes,
}

export default withProfile(ManageDeposit)
